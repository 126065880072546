import React, { useEffect, useState } from 'react'

// Utils
import { ShowErrorMsg } from '../../utils/ShowErrorMsg'

// Components
import IndexHeader from '../../components/IndexHeader'
import SubTopNav from '../../components/sub-page/SubTopNav'
import Loading from '../../components/common/Loading'
import { Sapienza } from '../../data/SubTopNavInfo'

// Images, Videos
import { images } from '../../assets/images'
import { Link } from 'react-router-dom'
import axios from 'axios'
import DOMPurify from 'dompurify'
import NoContent from '../../components/common/NoContent'

const Counselor = () => {
   IndexHeader();

   const [listData, setlistData] = useState();

   const fetchData = async () => {
      try {
         const response = await axios.post(`${process.env.REACT_APP_API_URL}/counselor/list`)
         setlistData(response.data.data)
      } catch (error) {
         console.error(error)
         ShowErrorMsg(error)
      }
   }

   useEffect(() => {
      fetchData();
   }, [])

   if (!listData) return <Loading />

   const placeList = {
      0: '고양삼송점',
      1: '춘천점',
      2: '인천계양점',
      3: '제주점'
   }

   return (
      <main className="subpage counselor">
         <div className="sub-top">
            <img src={images.sub05_sub_top} alt="" className="bg-img" />
            <div className="sub-top-title-wrap row-group">
               <h2 className="sub-top-title">
                  상담사 · 센터소개
               </h2>
               <p className="sub-top-txt">
                  사피엔자 아카데미 융합심리분석상담사
               </p>
            </div>
         </div>

         <SubTopNav SubTopNavInfo={Sapienza()} />

         <div className="section section-01">
            <div className="container">
               <div className="section-title-wrap row-group">
                  <p className="sub-title green">
                     마인드 임팩트 상담·코칭센터
                  </p>
                  <h3 className="title">
                     전문 융합심리분석상담사
                  </h3>
                  <p className="txt">
                     ‘사피엔자 아카데미아’를 통해 양성된 융합심리분석상담사들이 마인드 아이티만의 고유한 검사도구와 분석키트를 <br /> 활용하여 융합심리분석상담과 융합심리분석상담치료를 진행합니다.
                  </p>
               </div>
               <img src={images.sub05_counselor_01} alt="" className="main-img img-pc" />
               <img src={images.sub05_counselor_01_m} alt="" className="main-img img-mb" />
            </div>
         </div>
         <div className="section section-02">
            <div className="container">
               <div className="section-title-wrap">
                  <h3 className="title">
                     Counseling · Coaching Staff
                  </h3>
               </div>
               <div className="counselor-list col-group">
                  {listData.length > 0 ?
                     listData.map((listItem, index) =>
                        <div className="counselor-item col-group" key={index}>
                           <div className="img">
                              <img src={listItem.img.file_addr} alt="프로필" />
                           </div>
                           <div className="txt-wrap row-group">
                              <div className="title-wrap row-group">
                                 <p className="sub-title green">
                                    {placeList[listItem.type]}
                                 </p>
                                 <p className="title">
                                    {listItem.name}
                                 </p>
                                 <p
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(listItem.introduction) }}
                                    className="txt"
                                 />
                              </div>
                              <div className="txt-group row-group">
                                 {listItem.historys.map((history, index) =>
                                    <p className="txt" key={index}>
                                       {history.content}
                                    </p>
                                 )}
                              </div>
                           </div>
                        </div>
                     )
                     : <NoContent />
                  }
               </div>
            </div>
         </div>
         <div className="section-footer">
            <div className="container col-group">
               <p className="title">
                  마인드 인사이트의 <strong>융합심리분석상담</strong>으로 <strong>'나'</strong>를 찾아보세요
               </p>
               <Link to="/login/select" className="btn col-group">
                  상담사 로그인
                  <i></i>
               </Link>
            </div>
         </div>
      </main>
   )
}

export default Counselor