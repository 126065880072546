import { useEffect } from "react";

const useDisableScroll = (isDisabled) => {
  useEffect(() => {
    const disableScroll = () => {
      const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

      if (isDisabled) {
        document.body.style.overflow = "hidden";
        document.body.style.height = "100vh";
        document.body.style.paddingRight = `${scrollBarWidth}px`;
      } else {
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
        document.body.style.paddingRight = "0px";
      }
    };

    disableScroll();

    return () => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    };
  }, [isDisabled]);
};

export default useDisableScroll;
