import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

// Components
import CommonUi from '../../../components/insight-test/CommonUi';

const Content = () => {

   const navigate = useNavigate();

   // 생년월일을 한국 나이로 계산
   const [koreanAge, setKoreanAge] = useState(0);

   // 유저 정보
   const [userInfo, setUserInfo] = useState({
      birth: '',
      agreement: false,
   });

   // 개인정보 모달
   const [isVisiblePrivacy, setIsVisiblePrivacy] = useState(false);

   // 생년월일에 "-" 추가 
   const formatDate = (input) => {
      const year = input.slice(0, 4);
      const month = input.slice(4, 6);
      const day = input.slice(6, 8);
      return `${year}-${month}-${day}`;
   }

   // 유저 정보 업데이트
   const handleChange = (e) => {
      const { name, value, checked } = e.target;

      setUserInfo((prev) => ({
         ...prev,
         [name]: value
      }))

      // 개인정보 수집 동의일 때 agreement값을 1또는 0으로
      if (name === 'agreement') {
         setUserInfo((prev) => ({
            ...prev,
            agreement: checked
         }))
      }

      // 생년월일 일 때 생일 계산
      // 생년월일 일 때 생일 계산
      if (name === 'birth' && value.length <= 8) {
         setUserInfo((prev) => ({
            ...prev,
            [name]: value
         }))

         if (value.length === 8) {
            const formatValue = formatDate(value)
            const selectedDate = new Date(formatValue);

            const today = new Date();
            const currentYear = today.getFullYear();
            const birthYear = selectedDate.getFullYear();

            // 한국식 나이 계산 (태어난 해 기준 1살로 시작)
            const calculatedKoreanAge = currentYear - birthYear + 1;
            setKoreanAge(calculatedKoreanAge);
         } else {
            setKoreanAge(0)
         }
      }
   }

   // ===== 유저 정보 데이터 관련 =====
   // 쿠키 값 암호화 
   const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

   const encrypt = (text) => {
      return CryptoJS.AES.encrypt(text, SECRET_KEY).toString();
   };

   // 데이터 쿠키에 저장
   const sendUserInfo = async (e) => {
      e.preventDefault(); // 기본 동작 막음

      if (userInfo.birth.length !== 8) {
         alert('생년월일을 8자리로 입력해 주세요.')
         return;
      }
      if (!userInfo.agreement) {
         alert('개인정보수집 및 이용에 동의해 주세요.')
         return;
      }

      const updateData = { ...userInfo }
      updateData.birth = formatDate(updateData.birth) // 생년월일에 - 추가

      // 쿠키에 저장하는 함수 추가
      const saveUserInfoToCookie = async () => {
         const encryptedName = encrypt(JSON.stringify({ birth: updateData.birth, age: koreanAge })); // 암호화
         Cookies.set('userData', encryptedName)
      }

      await saveUserInfoToCookie();
      navigate('/life-score/guide')
   }

   return (
      <>
         <div className="content">
            <div className="content-title-wrap row-group border">
               <h3 className="content-title">
                  정보입력
               </h3>
            </div>
            <form
               style={{ marginTop: '40px' }}
               onSubmit={sendUserInfo}
            >
               <fieldset>
                  <legend className='hide-g'>정보입력</legend>
                  <div className="form-wrap row-group">
                     <div className="form-item row-group">
                        <div className="item-default">
                           생년월일 <span className="red">*</span>
                        </div>
                        <div className="item-user">
                           <div className="form-input-wrap col-group">
                           <input
                                 onChange={handleChange}
                                 value={userInfo.birth}
                                 required={true}
                                 name='birth'
                                 type='number'
                                 placeholder='생년월일 8자리'
                                 className="form-date form-input"
                              />
                              {koreanAge > 0 &&
                                 <span className="green">&#40;{koreanAge}세&#41;</span>
                              }
                           </div>
                        </div>
                     </div>
                     <div className="form-item">
                        <div className="join-agree-item join-agree-item-all col-group">
                           <label htmlFor="agree_0">
                              <input
                                 onChange={handleChange}
                                 checked={userInfo.agreement}
                                 name='agreement'
                                 type="checkbox"
                                 className="form-checkbox"
                                 id="agree_0"
                              />
                              <div className="checked-item col-group">
                                 <div className="icon">
                                    <i className="xi-check"></i>
                                 </div>
                                 <p className="txt">
                                    개인정보수집 및 이용에 동의하시겠습니까?
                                 </p>
                              </div>
                           </label>
                           <button
                              onClick={() => setIsVisiblePrivacy(prev => !prev)}
                              type='button'
                              className="more-btn"
                           >
                              보기
                           </button>
                        </div>
                     </div>
                  </div>
                  <div className="form-footer">
                     <button
                        type='submit'
                        className="form-footer-btn"
                     >
                        다음
                     </button>
                  </div>
               </fieldset>
            </form>
         </div>
         <PrivacyModal
            isVisiblePrivacy={isVisiblePrivacy}
            setIsVisiblePrivacy={setIsVisiblePrivacy}
         />
      </>
   )
}


// 개인정보수집 및 이용 동의 보기 버튼 클릭 시 나타나는 팝업
export const PrivacyModal = ({ isVisiblePrivacy, setIsVisiblePrivacy }) => {

   if (!isVisiblePrivacy) return;

   return (
      <div className="modal-container">
         <div className="modal-wrap">
            <i onClick={() => setIsVisiblePrivacy(prev => !prev)} className="close-btn pc xi-close" />
            <i onClick={() => setIsVisiblePrivacy(prev => !prev)} className="close-btn mb xi-close" />
            <div className="modal-title-wrap">
               <p className="modal-title">
                  개인정보수집 및 이용 동의
               </p>
            </div>

            <div className="modal-table-wrap">
               <table>
                  <thead>
                     <tr>
                        <th>수집항목</th>
                        <th>수집목적</th>
                        <th>보유·이용기간</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>이름, 전화번호, 생년월일</td>
                        <td>회원식별</td>
                        <td>관련 법령에 따름</td>
                     </tr>
                     <tr>
                        <td>주소</td>
                        <td>상품배송</td>
                        <td>관련 법령에 따름</td>
                     </tr>
                  </tbody>
               </table>
            </div>

            <div className="modal-alert-txt">
               귀하는 위와 같이 개인정보를 수집•이용하는데 동의를 거부할 권리가 있습니다.  <br />
               필수 수집 항목에 대한 동의를 거절하는 경우 서비스 이용이 제한 될 수 있습니다.  <br />
               선택 수집 항목에 동의를 하지 않으시는 경우 별도의 불이익은 없습니다.
            </div>
         </div>
      </div >
   )
}

const LifeScoreUserInfoInput = () => {

   return (
      <CommonUi
         isLifeScore={true}
         mainStep={0}
         content={<Content />}
      />
   )
}

export default LifeScoreUserInfoInput