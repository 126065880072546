export const images = {
   face_1: require('../pages/mind-insight-test/result-pages/images/face_1.png'),
   face_2: require('../pages/mind-insight-test/result-pages/images/face_2.png'),
   face_3: require('../pages/mind-insight-test/result-pages/images/face_3.png'),
   face_4: require('../pages/mind-insight-test/result-pages/images/face_4.png'),
   face_5: require('../pages/mind-insight-test/result-pages/images/face_5.png'),
   face_6: require('../pages/mind-insight-test/result-pages/images/face_6.png'),

   icon_energy_type_line: require('../pages/mind-insight-test/result-pages/images/icon_energy_type_line.png'),
   icon_w: require('../pages/mind-insight-test/result-pages/images/icon_w.png'),
   icon_d: require('../pages/mind-insight-test/result-pages/images/icon_d.png'),
   icon_p: require('../pages/mind-insight-test/result-pages/images/icon_p.png'),
   icon_i: require('../pages/mind-insight-test/result-pages/images/icon_i.png'),
   icon_l: require('../pages/mind-insight-test/result-pages/images/icon_l.png'),
   icon_m: require('../pages/mind-insight-test/result-pages/images/icon_m.png'),
   icon_s: require('../pages/mind-insight-test/result-pages/images/icon_s.png'),
   icon_c: require('../pages/mind-insight-test/result-pages/images/icon_c.png'),

   prescription: require('../pages/mind-insight-test/result-pages/images/prescription.png'),
   index_cover: require('../pages/mind-insight-test/result-pages/images/index_cover.png'),
   section_top: require('../pages/mind-insight-test/result-pages/images/section_top.png'),
   icon_arrow_bold_down: require('../pages/mind-insight-test/result-pages/images/icon_arrow_bold_down.png'),
   icon_arrow_bold_down_orange: require('../pages/mind-insight-test/result-pages/images/icon_arrow_bold_down_orange.png'),
   depression_01: require('../pages/mind-insight-test/result-pages/images/depression_01.png'),
   depression_02: require('../pages/mind-insight-test/result-pages/images/depression_02.png'),
   relax_tip_01: require('../pages/mind-insight-test/result-pages/images/relax_tip_01.png'),
   relax_tip_02: require('../pages/mind-insight-test/result-pages/images/relax_tip_02.png'),
   relax_item_01: require('../pages/mind-insight-test/result-pages/images/relax_item_01.png'),
   relax_item_02: require('../pages/mind-insight-test/result-pages/images/relax_item_02.png'),
   relax_item_03: require('../pages/mind-insight-test/result-pages/images/relax_item_03.png'),
   relax_item_04: require('../pages/mind-insight-test/result-pages/images/relax_item_04.png'),
   relax_item_05: require('../pages/mind-insight-test/result-pages/images/relax_item_05.png'),
   anger: require('../pages/mind-insight-test/result-pages/images/anger.png'),
   measure_item_01: require('../pages/mind-insight-test/result-pages/images/measure_item_01.png'),
   measure_item_02: require('../pages/mind-insight-test/result-pages/images/measure_item_02.png'),
   measure_item_03: require('../pages/mind-insight-test/result-pages/images/measure_item_03.png'),
   measure_item_04: require('../pages/mind-insight-test/result-pages/images/measure_item_04.png'),
   measure_item_05: require('../pages/mind-insight-test/result-pages/images/measure_item_05.png'),
   compare_title_item_01: require('../pages/mind-insight-test/result-pages/images/compare_title_item_01.png'),
   compare_title_item_02: require('../pages/mind-insight-test/result-pages/images/compare_title_item_02.png'),
   measure_item_06: require('../pages/mind-insight-test/result-pages/images/measure_item_06.png'),
   measure_item_07: require('../pages/mind-insight-test/result-pages/images/measure_item_07.png'),
   measure_item_08: require('../pages/mind-insight-test/result-pages/images/measure_item_08.png'),
   icon_music_green: require('../pages/mind-insight-test/result-pages/images/icon_music_green.svg'),
   icon_physical_green: require('../pages/mind-insight-test/result-pages/images/icon_physical_green.svg'),
   icon_space: require('../pages/mind-insight-test/result-pages/images/icon_space.svg'),
   icon_space_red: require('../pages/mind-insight-test/result-pages/images/icon_space_red.svg'),
   icon_space_green: require('../pages/mind-insight-test/result-pages/images/icon_space_green.svg'),
   icon_nature: require('../pages/mind-insight-test/result-pages/images/icon_nature.svg'),
   icon_nature_red: require('../pages/mind-insight-test/result-pages/images/icon_nature_red.svg'),
   icon_nature_green: require('../pages/mind-insight-test/result-pages/images/icon_nature_green.svg'),
   icon_friendly: require('../pages/mind-insight-test/result-pages/images/icon_friendly.svg'),
   icon_friendly_red: require('../pages/mind-insight-test/result-pages/images/icon_friendly_red.svg'),
   icon_friendly_green: require('../pages/mind-insight-test/result-pages/images/icon_friendly_green.svg'),
   icon_personal: require('../pages/mind-insight-test/result-pages/images/icon_personal.svg'),
   icon_personal_red: require('../pages/mind-insight-test/result-pages/images/icon_personal_red.svg'),
   icon_personal_green: require('../pages/mind-insight-test/result-pages/images/icon_personal_green.svg'),
   icon_physical: require('../pages/mind-insight-test/result-pages/images/icon_physical.svg'),
   icon_physical_red: require('../pages/mind-insight-test/result-pages/images/icon_physical_red.svg'),
   icon_lang: require('../pages/mind-insight-test/result-pages/images/icon_lang.svg'),
   icon_lang_green: require('../pages/mind-insight-test/result-pages/images/icon_lang_green.svg'),
   icon_lang_red: require('../pages/mind-insight-test/result-pages/images/icon_lang_red.svg'),
   icon_logic: require('../pages/mind-insight-test/result-pages/images/icon_logic.svg'),
   icon_logic_green: require('../pages/mind-insight-test/result-pages/images/icon_logic_green.svg'),
   icon_logic_red: require('../pages/mind-insight-test/result-pages/images/icon_logic_red.svg'),
   icon_music: require('../pages/mind-insight-test/result-pages/images/icon_music.svg'),
   icon_music_red: require('../pages/mind-insight-test/result-pages/images/icon_music_red.svg'),
   personality_type: require('../pages/mind-insight-test/result-pages/images/personality_type.png'),
   balance_center: require('../pages/mind-insight-test/result-pages/images/balance_center.png'),
   balance_right: require('../pages/mind-insight-test/result-pages/images/balance_right.png'),
   balance_left: require('../pages/mind-insight-test/result-pages/images/balance_left.png'),
   balance_core: require('../pages/mind-insight-test/result-pages/images/balance_core.png'),
   balance_of_power: require('../pages/mind-insight-test/result-pages/images/balance_of_power.png'),
   custom_balance_of_power: require('../pages/mind-insight-test/result-pages/images/custom_balance_of_power.png'),
   graph_wing: require('../pages/mind-insight-test/result-pages/images/graph_wing.png'),
   graph_arrow: require('../pages/mind-insight-test/result-pages/images/graph_arrow.png'),
   icon_ego_jc: require('../pages/mind-insight-test/result-pages/images/icon_ego_jc.png'),
   icon_ego_ec: require('../pages/mind-insight-test/result-pages/images/icon_ego_ec.png'),
   icon_ego_mr: require('../pages/mind-insight-test/result-pages/images/icon_ego_mr.png'),
   icon_ego_fe: require('../pages/mind-insight-test/result-pages/images/icon_ego_fe.png'),
   icon_ego_ae: require('../pages/mind-insight-test/result-pages/images/icon_ego_ae.png'),
   age_icon_0_on: require('../pages/mind-insight-test/result-pages/images/age_icon_0_on.png'),
   age_icon_1_on: require('../pages/mind-insight-test/result-pages/images/age_icon_1_on.png'),
   age_icon_2_on: require('../pages/mind-insight-test/result-pages/images/age_icon_2_on.png'),
   age_icon_3_on: require('../pages/mind-insight-test/result-pages/images/age_icon_3_on.png'),
   age_icon_4_on: require('../pages/mind-insight-test/result-pages/images/age_icon_4_on.png'),
   age_icon_5_on: require('../pages/mind-insight-test/result-pages/images/age_icon_5_on.png'),
   age_icon_6_on: require('../pages/mind-insight-test/result-pages/images/age_icon_6_on.png'),
   age_icon_7_on: require('../pages/mind-insight-test/result-pages/images/age_icon_7_on.png'),
   age_icon_1: require('../pages/mind-insight-test/result-pages/images/age_icon_1.png'),
   age_icon_2: require('../pages/mind-insight-test/result-pages/images/age_icon_2.png'),
   age_icon_3: require('../pages/mind-insight-test/result-pages/images/age_icon_3.png'),
   age_icon_4: require('../pages/mind-insight-test/result-pages/images/age_icon_4.png'),
   age_icon_5: require('../pages/mind-insight-test/result-pages/images/age_icon_5.png'),
   age_icon_6: require('../pages/mind-insight-test/result-pages/images/age_icon_6.png'),
   age_icon_7: require('../pages/mind-insight-test/result-pages/images/age_icon_7.png'),
   icon_life_score: require('../pages/mind-insight-test/result-pages/images/icon_life_score.png'),
   icon_life_orange: require('../pages/mind-insight-test/result-pages/images/icon_life_score_orange.png'),
   Reconstruction: require('../pages/mind-insight-test/result-pages/images/Reconstruction.png'),
   section_top_02: require('../pages/mind-insight-test/result-pages/images/section_top_02.png'),
   icon_result_score_01: require('../pages/mind-insight-test/result-pages/images/icon_result_score_01.png'),
   icon_result_score_02: require('../pages/mind-insight-test/result-pages/images/icon_result_score_02.png'),
   icon_result_score_03: require('../pages/mind-insight-test/result-pages/images/icon_result_score_03.png'),
   icon_result_score_04: require('../pages/mind-insight-test/result-pages/images/icon_result_score_04.png'),
   icon_result_score_01_active: require('../pages/mind-insight-test/result-pages/images/icon_result_score_01_active.png'),
   icon_result_score_02_active: require('../pages/mind-insight-test/result-pages/images/icon_result_score_02_active.png'),
   icon_result_score_03_active: require('../pages/mind-insight-test/result-pages/images/icon_result_score_03_active.png'),
   icon_result_score_04_active: require('../pages/mind-insight-test/result-pages/images/icon_result_score_04_active.png'),
   result_score_img_03: require('../pages/mind-insight-test/result-pages/images/result_score_img_03.png'),
   life_score_section_0: require('../pages/mind-insight-test/result-pages/images/life_score_section_0.png'),
   life_score_section_1: require('../pages/mind-insight-test/result-pages/images/life_score_section_1.png'),
   life_score_section_2: require('../pages/mind-insight-test/result-pages/images/life_score_section_2.png'),
   life_score_section_3: require('../pages/mind-insight-test/result-pages/images/life_score_section_3.png'),
   life_score_section_4: require('../pages/mind-insight-test/result-pages/images/life_score_section_4.png'),
   life_score_section_5: require('../pages/mind-insight-test/result-pages/images/life_score_section_5.png'),
   life_score_section_6: require('../pages/mind-insight-test/result-pages/images/life_score_section_6.png'),
   life_score_section_7: require('../pages/mind-insight-test/result-pages/images/life_score_section_7.png'),
   growth_strategy_01: require('../pages/mind-insight-test/result-pages/images/growth_strategy_01.png'),
   growth_strategy_02: require('../pages/mind-insight-test/result-pages/images/growth_strategy_02.png'),
   letter_cover: require('../pages/mind-insight-test/result-pages/images/letter_cover.png'),
   letter_bg: require('../pages/mind-insight-test/result-pages/images/letter_bg.png'),
   metaq_1: require('../pages/mind-insight-test/result-pages/images/metaq-1.png'),
   metaq_2: require('../pages/mind-insight-test/result-pages/images/metaq-2.png'),
   metaq_3: require('../pages/mind-insight-test/result-pages/images/metaq-3.png'),
   metaq_4: require('../pages/mind-insight-test/result-pages/images/metaq-4.png'),
   metaq_5: require('../pages/mind-insight-test/result-pages/images/metaq-5.png'),
   metaq_6: require('../pages/mind-insight-test/result-pages/images/metaq-6.png'),
   metaq_7: require('../pages/mind-insight-test/result-pages/images/metaq-7.png'),
   metaq_8: require('../pages/mind-insight-test/result-pages/images/metaq-8.png'),
   metaq_9: require('../pages/mind-insight-test/result-pages/images/metaq-9.png'),
   metaq_10: require('../pages/mind-insight-test/result-pages/images/metaq-10.png'),
   metaq_11: require('../pages/mind-insight-test/result-pages/images/metaq-11.png'),
   metaq_12: require('../pages/mind-insight-test/result-pages/images/metaq-12.png'),
   metaq_13: require('../pages/mind-insight-test/result-pages/images/metaq-13.png'),
   metaq_14: require('../pages/mind-insight-test/result-pages/images/metaq-14.png'),
   metaq_15: require('../pages/mind-insight-test/result-pages/images/metaq-15.png'),
   metaq_16: require('../pages/mind-insight-test/result-pages/images/metaq-16.png'),
   metaq_17: require('../pages/mind-insight-test/result-pages/images/metaq-17.png'),

   // life Score
   life_score_cover: require('../images/life-score/life_score_cover.png'),
   life_score_intro: require('../images/life-score/intro.png'),
   life_score_sub_banner: require('../images/life-score/sub_banner.png'),
   r_bg: require('../images/life-score/r_bg.png'),
}