import React from 'react'
import styled from 'styled-components'
import { images } from '../../assets/images'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { LoginInfoAtom } from '../../recoil/LoginInfoAtom'

const MobileBarContainer = styled.div`
z-index: 9990;
position: fixed;
bottom: 0px;
left: 0px;
width: 100%;
height: 68px;
background-color: #1e3932;
.category-wrap{
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   height: 100%;
   .category{
      display: flex;
      justify-content: center;
      align-items: center;
      .btn-link{
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         gap: 8px;
         .icon{
            width: 24px;
            height: auto;
         }
         .name{
            font-size: 10px;
            font-weight: 500;
            color: #fff;
         }
      }
   }
}
`

const MobileBar = () => {

   // 로그인 정보
   const loginInfo = useRecoilValue(LoginInfoAtom);

   // 상담사인지 확인
   const isCounselor = loginInfo?.member_type === 1;

   const categoryList = [
      { path: '/', name: '홈' },
      { path: '/mind-insight/mind-insight', name: '검사하기' },
      { path: '/my-page/inquiry', name: '문의하기' },
      { path: `${isCounselor ? '/my-page/counselor/code-send' : '/my-page/general-user/code-use-gift'}`, name: '마이페이지' },
   ]

   return (
      <MobileBarContainer>
         <ul className='category-wrap'>
            {categoryList.map((category, index) =>
               <li className='category' key={index}>
                  <Link className='btn-link' to={category.path}>
                     <img className='icon' src={images[`icon_mobile_bar_${index + 1}`]} alt="" />
                     <p className='name'>{category.name}</p>
                  </Link>
               </li>
            )}
         </ul>
      </MobileBarContainer>
   )
}

export default MobileBar