import React, { useEffect, useState } from 'react'

// Components
import Pagination from '../../../../components/common/Pagination'
import RefundPopup from '../../../../components/user/refund-request/RefundPopup'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../../../components/common/Loading';
import AccessToken from '../../../../hooks/token/AccessToken';
import NoContent from '../../../../components/common/NoContent';
import { ShowErrorMsg } from '../../../../utils/ShowErrorMsg';

const PurchaseHistory = ({ pageType }) => {

   const accessToken = AccessToken();

   const [purchaseData, setPurchaseData] = useState(); // 구매내역 데이터
   const [pageIndex, setPageIndex] = useState(1); // pagination 페이지 조작

   // 환불 요청 팝업
   const [refundPopup, setRefundPopup] = useState({
      visible: false,
      data: null,
   });

   // ===== 정렬 기준 변경 =====
   const [orderType, setOrderType] = useState(0);
   const orderTypeChange = (e) => {
      const dataOrder = e.target.dataset.order;
      setOrderType(Number(dataOrder))
   }

   // ===== 데이터 가져오기 =====
   const apiType = {
      0: {
         apiUrl: 'purchase',
         title: '구매내역',
         thName: '결제금액',
         detailViewId: 'purchase_id',
         reverse: {
            title: '환불내역',
            url: '/my-page/refund/history',
         },
      },
      1: {
         apiUrl: 'refund',
         title: '환불내역',
         thName: '상태/요청일자',
         detailViewId: 'id',
         reverse: {
            title: '구매내역',
            url: '/my-page/purchase/history',
         },
      }
   }
   const fetchData = async () => {
      window.scrollTo(0, 0)
      try {
         const response = await axios.get(`${process.env.REACT_APP_API_URL}/mypage/${apiType[pageType].apiUrl}/list?order=${orderType}&start_date=${selectDate.startDate}&end_date=${selectDate.endDate}&search=${searchValue}&page=${pageIndex}`, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         })
         setPurchaseData(response.data.data);
      } catch (error) {
         console.error(error)
         ShowErrorMsg(error)
      }
   }

   useEffect(() => {
      fetchData();
   }, [pageType, orderType, pageIndex])

   // ===== 기간 설정 =====
   const [selectDate, setSelectDate] = useState({
      startDate: '',
      endDate: '',
   })
   const selectedDateChange = (e) => {
      const { name, value } = e.target

      setSelectDate((prev) => ({
         ...prev,
         [name]: value
      }))
   }
   const selectedDateSearch = () => {
      fetchData();
   }

   // ===== 주문번호 검색 =====
   const [searchValue, setSearchValue] = useState('');
   const searchValueChange = (e) => {
      const value = e.target.value;
      setSearchValue(value);
   }
   const searchSubmit = (e) => {
      e.preventDefault();
      fetchData();
   }

   // ===== 환불 팝업 =====
   const refundPopupOpen = (currentItemData) => {
      setRefundPopup((prev) => ({
         ...prev,
         visible: true,
         data: currentItemData
      }))
   }

   // ===== 환불요청 취소 =====
   const cancelRefund = async (currentItemData) => {
      if (window.confirm('선택하신 상품의 환불 요청을 취소하시겠습니까?')) {
         try {
            await axios.post(`${process.env.REACT_APP_API_URL}/mypage/cancle/refund`, {
               id: currentItemData.id
            }, {
               headers: {
                  Authorization: `Bearer ${accessToken}`
               }
            })
            alert('환불 요청이 취소되었습니다.')
            fetchData();
         } catch (error) {
            console.error(error)
            ShowErrorMsg(error)
         }
      }
   }

   // ===== state Labels =====
   const stateLabels = {
      2: '환불요청',
      3: '환불완료',
      4: '환불반려',
   };

   if (!purchaseData) return <Loading />

   return (
      <div className="myPageContent purchase">
         <div className="mp-main-title-wrap border col-group">
            <h2 className="mp-main-title">
               <span className="mb">My 쇼핑 <i></i></span>
               {apiType[pageType].title}
            </h2>
            <Link to={apiType[pageType].reverse.url} className="mp-main-btn">
               {apiType[pageType].reverse.title}
            </Link>
            <div className="search-wrap">
               <form onSubmit={searchSubmit}>
                  <input
                     onChange={searchValueChange}
                     value={searchValue}
                     type="text"
                     className="search-input"
                     placeholder="주문번호를 입력하세요"
                  />
                  <button
                     type='submit'
                     className="search-btn"
                  >
                     <i></i>
                  </button>
               </form>
            </div>
         </div>

         <div className="mp-date-search-wrap col-group">
            <div className="mp-date-search-item col-group">
               <div className="mp-date-search-group col-group">
                  <label htmlFor="type_01" className="mp-month-item">
                     <input
                        onChange={orderTypeChange}
                        checked={orderType === 0}
                        type="radio"
                        id="type_01"
                        name="type"
                        data-order='0'
                        className="form-checkbox"
                     />
                     <p className="mp-month-title row-group">전체보기</p>
                  </label>
                  <label htmlFor="type_02" className="mp-month-item">
                     <input
                        onChange={orderTypeChange}
                        checked={orderType === 1}
                        type="radio"
                        id="type_02"
                        name="type"
                        data-order='1'
                        className="form-checkbox"
                     />
                     <p className="mp-month-title row-group">마인드 인사이트</p>
                  </label>
                  <label htmlFor="type_03" className="mp-month-item">
                     <input
                        onChange={orderTypeChange}
                        checked={orderType === 2}
                        type="radio"
                        id="type_03"
                        name="type"
                        data-order='2'
                        className="form-checkbox"
                     />
                     <p className="mp-month-title row-group">마인드 테라피</p>
                  </label>
               </div>
            </div>
            <div className="mp-date-search-item col-group">
               <p className="mp-date-search-txt only-pc">
                  기간 조회
               </p>
               <div className="mp-date-search-group mp-date-input-group col-group">
                  <input
                     onChange={selectedDateChange}
                     name='startDate'
                     type="date"
                     className="mp-date-input"
                  />
                  -
                  <input
                     onChange={selectedDateChange}
                     name='endDate'
                     type="date"
                     className="mp-date-input"
                  />
               </div>
               <button
                  onClick={selectedDateSearch}
                  className="mp-date-search-btn"
               >
                  조회
               </button>
            </div>
         </div>

         <div className="mp-section-wrap row-group">
            <div className="mp-section">
               <div className="table-wrap prod-table-wrap prod-table-top">
                  <table className="prod-table">
                     <colgroup>
                        <col width="40%" />
                     </colgroup>
                     <thead>
                        <tr>
                           <th className="left">
                              상품정보
                           </th>
                           <th>
                              주문번호/주문일자
                           </th>
                           <th>
                              수량
                           </th>
                           <th>
                              {apiType[pageType].thName}
                           </th>
                           <th>
                              상세
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        {purchaseData.list.data.length > 0 ?
                           purchaseData.list.data.map((listItem, index) =>
                              <tr key={index}>
                                 <td>
                                    <div className="prod-table-item col-group">
                                       <div className="img-box">
                                          <img src={listItem.info.img.file_addr} alt="상품 썸네일" />
                                       </div>
                                       <div className="txt-wrap row-group">
                                          <p className="prod-item-label">
                                             {listItem.type === 3 ? 'Mind Therapy' : 'Mind Insight'}
                                          </p>
                                          <p className="prod-item-title">
                                             {listItem.info.product_name}
                                          </p>
                                          <p className="prod-table-item-option">
                                             옵션 : {listItem.option_name} &#40;+ {(listItem.option_price).toLocaleString()}원&#41;
                                             <span className="mb"> / {listItem.real_buy_quantity}개</span>
                                          </p>
                                          <p className="prod-table-item-option price mb">
                                             {pageType === 0 && listItem.total_price
                                                ? <span>
                                                   <strong>{(listItem.total_price).toLocaleString()}</strong>원
                                                </span>
                                                : <strong className={`${listItem.state !== 3 ? 'red' : ''}`}>{stateLabels[listItem.state]}</strong>
                                             }
                                          </p>
                                          {listItem.type === 3 &&
                                             <p className="prod-table-item-option red">
                                                {listItem.refund_imp_date} 이후 환불불가
                                             </p>
                                          }
                                       </div>
                                    </div>
                                 </td>
                                 <td className="top">
                                    <div className="prod-item-order-group row-group">
                                       <Link to={`/my-page/purchase/detail/${listItem.purchase_id}`} className="prod-item-order-number">
                                          {listItem.info.order_number}
                                       </Link>
                                       <p className="prod-item-txt">
                                          {listItem.format_created_at}
                                       </p>
                                    </div>
                                 </td>
                                 <td className='hide'>
                                    <p className="prod-item-txt">
                                       <strong>{listItem.real_buy_quantity}</strong>
                                    </p>
                                 </td>

                                 <td className='hide'>
                                    <p className="prod-item-txt">
                                       {pageType === 0 && listItem.total_price
                                          ? <span>
                                             <strong>{(listItem.total_price).toLocaleString()}</strong>원
                                          </span>
                                          : <strong className={`${listItem.state !== 3 ? 'red' : ''}`}>{stateLabels[listItem.state]}</strong>
                                       }
                                    </p>
                                 </td>
                                 <td>
                                    <div className="prod-table-btn-group row-group">
                                       {listItem.refund_ok &&
                                          <button
                                             onClick={() => refundPopupOpen(listItem)}
                                             className="prod-table-btn gray cancel_btn"
                                          >
                                             환불요청
                                          </button>
                                       }
                                       {
                                          listItem.refund_cancle_ok &&
                                          <button
                                             onClick={() => cancelRefund(listItem)}
                                             className="prod-table-btn gray cancel_btn"
                                          >
                                             요청취소
                                          </button>
                                       }
                                       <Link
                                          data-test={`/my-page/${apiType[pageType].apiUrl}/detail/${listItem[apiType[pageType].detailViewId]}`}
                                          to={`/my-page/${apiType[pageType].apiUrl}/detail/${listItem[apiType[pageType].detailViewId]}`}
                                          className="prod-table-btn"
                                       >
                                          상세보기
                                       </Link>
                                    </div>
                                 </td>
                              </tr>
                           ) :
                           <tr>
                              <td colSpan='5'>
                                 <NoContent />
                              </td>
                           </tr>
                        }
                     </tbody>
                  </table>
               </div>

               <Pagination
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  data={purchaseData.list}
               />
            </div>
         </div>
         <div className="test-guide-txt-wrap">
            <p className="title">
               마인드 아이티 상품 환불 정책
            </p>
            <div className="test-guide-txt-group row-group">
               <div className="test-guide-txt row-group">
                  <p className="default">
                     1. 마인드 인사이트
                  </p>
                  <p className="user">
                     마인드 인사이트를 시작했거나 완료한 경우에는 환불되지 않습니다.
                  </p>
               </div>
               <div className="test-guide-txt row-group">
                  <p className="default">
                     2. 마인드 리포트
                  </p>
                  <p className="user">
                     주문 제작 상품으로 환불 및 반품이 불가합니다.
                  </p>
               </div>
               <div className="test-guide-txt row-group">
                  <p className="default">
                     3. 해석상담
                  </p>
                  <p className="user">
                     아래와 같은 경우 환불이 불가합니다. <br />
                     1&#41; 상담이 시작된 경우 <br />
                     2&#41; 상담 당일에 사전 협의 없이 참석하지 않은 경우
                  </p>
               </div>
               <div className="test-guide-txt row-group">
                  <p className="default">
                     4. 마인드 테라피
                  </p>
                  <p className="user">
                     1&#41; 10일전 100% 환불 <br />
                     2&#41; 7일전 50% 환불 <br />
                     3&#41; 3일전부터 환불불가
                  </p>
               </div>
            </div>
         </div>
         <div className='popup-wrap'>
            <RefundPopup
               refundPopup={refundPopup}
               setRefundPopup={setRefundPopup}
               fetchData={fetchData}
            />
         </div>
      </div>
   )
}

export default PurchaseHistory