import axios from 'axios'
import React, { useState } from 'react'
import AccessToken from '../../../../hooks/token/AccessToken'
import { ShowErrorMsg } from '../../../../utils/ShowErrorMsg';
import { ShowSuccessMsg } from '../../../../utils/ShowSuccessMsg';

const InquiryCreate = () => {

   const accessToken = AccessToken();

   // 입력 정보
   const [inputedData, setInputedData] = useState({
      title: '',
      question: '',
      inquiry_file: null,
   });

   // 입력 정보 변경
   const handleChangeInfo = (e) => {
      const { name, value } = e.target

      setInputedData((prev) => ({
         ...prev,
         [name]: value
      }))
   }

   // 파일 변경 처리
   const handleFileChange = (e) => {
      const file = e.target.files[0];
      setInputedData((prev) => ({
         ...prev,
         inquiry_file: file,
      }));
   };

   const sendData = async () => {

      // 유효성 검사
      if (inputedData.title === '' || inputedData.question === '') {
         alert('필수 항목을 모두 입력해 주세요.')
         return;
      }

      const formDataToSend = new FormData();

      formDataToSend.append('title', inputedData.title);
      formDataToSend.append('question', inputedData.question);
      // 파일은 선택사항
      if (inputedData.inquiry_file) {
         formDataToSend.append('inquiry_file', inputedData.inquiry_file);
      }

      try {
         const response = await axios.post(`${process.env.REACT_APP_API_URL}/mypage/inquiry/registration`,
            formDataToSend, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         })
         ShowSuccessMsg(response)
         window.location.href = '/my-page/inquiry';
      } catch (error) {
         console.error(error)
         ShowErrorMsg(error)
      }
   }

   return (
      <div className="myPageContent">
         <div className="mp-main-title-wrap border">
            <h2 className="mp-main-title">
               1:1 문의 등록
            </h2>
         </div>

         <div className="form-wrap row-group">
            <div className="form-item row-group">
               <div className="item-default">
                  제목
                  <span className="red">*</span>
               </div>
               <div className="item-user">
                  <input
                     onChange={handleChangeInfo}
                     value={inputedData.title}
                     name='title'
                     type="text"
                     className="form-input"
                     placeholder="제목"
                  />
               </div>
            </div>
            <div className="form-item row-group">
               <div className="item-default">
                  내용
                  <span className="red">*</span>
               </div>
               <div className="item-user">
                  <div className="form-textarea-wrap">
                     <textarea
                        onChange={handleChangeInfo}
                        value={inputedData.question}
                        name="question"
                        className="form-textarea"
                        placeholder="문의 내용을 입력해 주세요."
                     />
                  </div>
               </div>
            </div>
            <div className="form-item row-group">
               <div className="item-default">
                  파일첨부
               </div>
               <div className="item-user">
                  <div className="file-upload-wrap single col-group">
                     <input
                        onChange={handleFileChange}
                        name='inquiry_file'
                        type="file"
                        accept="image/*"
                        id="list_file"
                     />
                     <input
                        type="text"
                        className="form-input cursor-default"
                        placeholder={`${inputedData.inquiry_file ? inputedData.inquiry_file.name : '5MB이하'}`}
                        readOnly
                     />
                     <label className="file-upload-btn" htmlFor="list_file">
                        파일 업로드
                     </label>
                  </div>
               </div>
            </div>
            <div className="mp-section-footer border">
               <button
                  onClick={sendData}
                  type='button'
                  className="mp-footer-btn green"
               >
                  문의등록
               </button>
            </div>
         </div>
      </div>
   )
}

export default InquiryCreate