import React, { useEffect, useState } from 'react'
import Pagination from '../../../../components/common/Pagination'
import { Link } from 'react-router-dom'
import axios from 'axios'
import AccessToken from '../../../../hooks/token/AccessToken'
import Loading from '../../../../components/common/Loading'
import NoContent from '../../../../components/common/NoContent'

const Inquiry = () => {

   const accessToken = AccessToken();
   const [listData, setListData] = useState();
   const [pageIndex, setPageIndex] = useState(1);

   const fetchData = async () => {
      try {
         const response = await axios.get(`${process.env.REACT_APP_API_URL}/mypage/inquiry/list`, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         })
         setListData(response.data.data)
      } catch (error) {
         console.error(error)
      }
   }

   useEffect(() => {
      fetchData();
   }, [])

   if (!listData) return <Loading />

   return (
      <div className="myPageContent">
         <div className="mp-main-title-wrap col-group">
            <h2 className="mp-main-title">
               1:1 문의
               <p className="mp-main-sub-title">
                  마인드 인사이트에 궁금한 점이 있으신가요? 오른쪽 '문의등록' 버튼을 눌러 문의 내용을 등록해 주세요.
               </p>
            </h2>
         </div>

         <div className="table-wrap inquiry-table-wrap">
            <table>
               <colgroup>
                  <col width="10%" />
                  <col width="75%" />
                  <col width="15%" />
               </colgroup>
               <thead>
                  <th className="left">
                     상태
                  </th>
                  <th className="left">
                     제목
                  </th>
                  <th className="left">
                     등록일자
                  </th>
               </thead>
               <tbody>
                  {listData.list.data.length > 0 ?
                     listData.list.data.map((item, index) =>
                        <tr key={index}>
                           <td colspan="3">
                              <Link to={`/my-page/inquiry/${item.id}`} className="inquiry-table-item col-group">
                                 <div className="state">
                                    <strong className={`${item.answer ? 'blue' : 'red'}`}>
                                       {item.answer ? '답변완료' : '답변대기'}

                                    </strong>
                                 </div>
                                 <div className="title">
                                    {item.title}
                                 </div>
                                 <div className="date">
                                    {item.format_created_at}
                                 </div>
                              </Link>
                           </td>
                        </tr>
                     )
                     : <tr>
                        <td colspan='3'>
                           <NoContent />
                        </td>
                     </tr>
                  }
               </tbody>
            </table>
            <Link to="/my-page/inquiry/create" className="mp-footer-btn mp-inquiry-btn green">
               문의등록
            </Link>
         </div>

         <Pagination
            data={listData.list}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
         />
      </div>
   )
}

export default Inquiry