import React from 'react'
import { Link } from 'react-router-dom';

const SnsAgreement = ({ marketingTypeArr, setMarketingTypeArr }) => {

   // 이용약관 체크박스 onChange
   const handleMarketingCheck = (e, targetItem) => {
      const isChecked = e.target.checked;

      setMarketingTypeArr(prev => {
         const updateData = { ...prev, [targetItem]: isChecked };

         // option 체크(해제) 시 하위 항목들도 체크(해제)
         if (targetItem === 'option') {
            ['optionSMS', 'optionEmail', 'optionTel'].forEach(item => {
               updateData[item] = isChecked;
            });
         }

         // option 하위 항목이 하나라도 체크되어 있으면 option도 체크
         if (['optionSMS', 'optionEmail', 'optionTel'].some(item => updateData[item])) {
            updateData.option = true;
         } else {
            updateData.option = false;
         }

         return updateData;
      });
   };

   return (
      <>
         <div className="join-agree-item col-group">
            <label htmlFor="agree_3">
               <input
                  onChange={(e) => { handleMarketingCheck(e, 'option') }}
                  checked={marketingTypeArr.option}
                  type="checkbox"
                  className="marketing-agreement form-checkbox"
                  id="agree_3"
               />
               <div className="checked-item col-group">
                  <div className="icon">
                     <i className="xi-check"></i>
                  </div>
                  <p className="txt">[선택] 마케팅 정보 수신에 동의합니다.</p>
               </div>
            </label>
            <a href="/policy/marketing" target="_blank" rel='noopener noreferrer' className="more-btn">
               보기
            </a>
         </div>
         <div className="checked-item-sub col-group">
            <label htmlFor="agree_4">
               <input
                  onChange={(e) => { handleMarketingCheck(e, 'optionSMS') }}
                  checked={marketingTypeArr.optionSMS}
                  name="SMS/알림톡"
                  type="checkbox"
                  className="marketing-option form-checkbox"
                  id="agree_4"
               />
               <div className="checked-item col-group">
                  <div className="icon">
                     <i className="xi-check"></i>
                  </div>
                  <p className="txt">SMS/알림톡</p>
               </div>
            </label>
            <label htmlFor="agree_5">
               <input
                  onChange={(e) => { handleMarketingCheck(e, 'optionEmail') }}
                  checked={marketingTypeArr.optionEmail}
                  name="이메일"
                  type="checkbox"
                  className="marketing-option form-checkbox"
                  id="agree_5"
               />
               <div className="checked-item col-group">
                  <div className="icon">
                     <i className="xi-check"></i>
                  </div>
                  <p className="txt">이메일</p>
               </div>
            </label>
            <label htmlFor="agree_6">
               <input
                  onChange={(e) => { handleMarketingCheck(e, 'optionTel') }}
                  checked={marketingTypeArr.optionTel}
                  name="전화"
                  type="checkbox"
                  className="marketing-option form-checkbox"
                  id="agree_6"
               />
               <div className="checked-item col-group">
                  <div className="icon">
                     <i className="xi-check"></i>
                  </div>
                  <p className="txt">전화</p>
               </div>
            </label>
         </div>
      </>
   )
}

export default SnsAgreement