import React from 'react'
import { images } from '../../../assets/testPageImages'
import { Link } from 'react-router-dom'

const LifeScoreGuide = () => {
   return (
      <main>
         <div className="test-container">
            <div className="container row-group w1280">
               <nav className="nav col-group">
                  <h2 className="nav-title">
                     인생점수 검사
                  </h2>
                  <div className="nav-step-list col-group">
                     <div className="nav-step active col-group">
                        <i className="icon"></i>
                        <p className="txt">작성방법</p>
                        <i className="txt"></i>
                     </div>
                     <div className="nav-step col-group">
                        <i className="icon"></i>
                        <p className="txt">답안입력</p>
                        <i className="txt"></i>
                     </div>
                     <div className="nav-step col-group">
                        <i className="icon"></i>
                        <p className="txt">완료</p>
                     </div>
                  </div>
               </nav>

               <section className="col-group section">
                  <div className="side side-img">
                     <img src={images.life_score_sub_banner} alt="" className="side-img" />
                  </div>
                  <div className="content">
                     <div className="content-section border">
                        <div className="content-title-wrap row-group">
                           <h3 className="content-title">
                              작성방법
                           </h3>
                           <p className="content-title-txt">
                              각 시기마다 제시되는 각 문항을 읽어 보시고 그 만족도에 따라 점수를 매겨주세요. <br />
                              <span className="green">
                                 매우 아니다 1점 ~  매우 그렇다 10점
                              </span>
                           </p>
                        </div>
                        <div className="question active">
                           <div className="example-label">
                              예시
                           </div>
                           <div className="q_title">
                              <div className="num">01.</div>
                              <div className="title">
                                 당신의 어머니는 친절한 분이었나요?
                              </div>
                           </div>
                           <div className="q_item life">
                              <label for="q_1_10" className="score">
                                 <input type="radio" id="q_1_10" name="q_1" className="q_1" value="10" />
                                 <div className="check">
                                    <div className="bar"></div>
                                    <div className="bar-core"></div>
                                    <div className="num">10</div>
                                 </div>
                              </label>
                              <label for="q_1_9" className="score">
                                 <input type="radio" id="q_1_9" name="q_1" className="q_1" value="9" />
                                 <div className="check">
                                    <div className="bar"></div>
                                    <div className="bar-core"></div>
                                    <div className="num">9</div>
                                 </div>
                              </label>
                              <label for="q_1_8" className="score">
                                 <input type="radio" id="q_1_8" name="q_1" className="q_1" value="8" />
                                 <div className="check">
                                    <div className="bar"></div>
                                    <div className="bar-core"></div>
                                    <div className="num">8</div>
                                 </div>
                              </label>
                              <label for="q_1_7" className="score">
                                 <input type="radio" id="q_1_7" name="q_1" className="q_1" value="7" />
                                 <div className="check">
                                    <div className="bar"></div>
                                    <div className="bar-core"></div>
                                    <div className="num">7</div>
                                 </div>
                              </label>
                              <label for="q_1_6" className="score">
                                 <input type="radio" id="q_1_6" name="q_1" className="q_1" value="6" />
                                 <div className="check">
                                    <div className="bar"></div>
                                    <div className="bar-core"></div>
                                    <div className="num">6</div>
                                 </div>
                              </label>
                              <label for="q_1_5" className="score">
                                 <input type="radio" id="q_1_5" name="q_1" className="q_1" value="5" />
                                 <div className="check">
                                    <div className="bar"></div>
                                    <div className="bar-core"></div>
                                    <div className="num">5</div>
                                 </div>
                              </label>
                              <label for="q_1_4" className="score">
                                 <input type="radio" id="q_1_4" name="q_1" className="q_1" value="4" />
                                 <div className="check">
                                    <div className="bar"></div>
                                    <div className="bar-core"></div>
                                    <div className="num">4</div>
                                 </div>
                              </label>
                              <label for="q_1_3" className="score">
                                 <input type="radio" id="q_1_3" name="q_1" className="q_1" value="3" />
                                 <div className="check">
                                    <div className="bar"></div>
                                    <div className="bar-core"></div>
                                    <div className="num">3</div>
                                 </div>
                              </label>
                              <label for="q_1_2" className="score">
                                 <input type="radio" id="q_1_2" name="q_1" className="q_1" value="2" />
                                 <div className="check">
                                    <div className="bar"></div>
                                    <div className="bar-core"></div>
                                    <div className="num">2</div>
                                 </div>
                              </label>
                              <label for="q_1_1" className="score">
                                 <input type="radio" id="q_1_1" name="q_1" className="q_1" value="1" />
                                 <div className="check">
                                    <div className="bar"></div>
                                    <div className="bar-core"></div>
                                    <div className="num">1</div>
                                 </div>
                              </label>
                           </div>
                        </div>
                     </div>
                     <div className="content-section">
                        <div className="content-title-wrap row-group">
                           <h3 className="content-title">
                              검사실시 안내
                           </h3>
                           <p className="content-title-txt">
                              본 검사는 개인의 인생적 특성을 알아보기 위한 것으로 좋은 점수와 나쁜 점수가 없습니다. <br />
                              자신에게 더 편안하고 익숙한 점수를 선택해주세요.
                           </p>
                        </div>
                        <div className="guide-txt-list row-group">
                           <div className="guide-txt col-group">
                              <div className="num">1</div>
                              <p className="txt">
                                 본 검사는 개인의 인생적 특성을 알아보기 위한 것으로 자신이 맞다고 생각하는 점수를 매겨주세요.
                              </p>
                           </div>
                           <div className="guide-txt col-group">
                              <div className="num">2</div>
                              <p className="txt">
                                 평소 생각이나 행동 등 자신에게 더 편안하고 익숙한 것에 점수를 매겨주세요.
                              </p>
                           </div>
                           <div className="guide-txt col-group">
                              <div className="num">3</div>
                              <p className="txt">
                                 시간제한은 없으나 너무 깊이 생각하지 말고 편안하게 점수를 매겨주세요.
                              </p>
                           </div>
                           <div className="guide-txt col-group">
                              <div className="num">4</div>
                              <p className="txt">
                                 한 문항도 빠짐없이 모든 문항에 점수를 매겨주세요.
                              </p>
                           </div>
                        </div>
                     </div>
                     <div className="form-footer">
                        <Link to="/life-score/enter-answer" className="form-footer-btn">
                           검사시작
                        </Link>
                     </div>
                  </div>
               </section>

            </div>
         </div>
      </main>
   )
}

export default LifeScoreGuide