import React from 'react'

const NoContent = () => {
   return (
      <div className="null-txt row-group">
         <i className="icon"></i>
         내용이 존재하지 않습니다.
      </div>
   )
}

export default NoContent