import React from 'react'

// Components
import Id from '../../../../components/user/find-account/Id'

const FindId = () => {
   return (
      <Id isCounselor={false} />
   )
}

export default FindId