import axios from "axios"
import AccessToken from "./AccessToken"
import { ShowErrorMsg } from "../../utils/ShowErrorMsg";

const FetchLogout = async () => {

   const accessToken = AccessToken();
   try {
      await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, {
         headers: {
            Authorization: `Bearer ${accessToken}`
         },
         withCredentials: true,
      })
      alert('로그아웃 되었습니다.')
      window.location.href = '/'
   } catch (error) {
      console.error(error)
      ShowErrorMsg(error)
   }
}

export default FetchLogout