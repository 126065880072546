import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import DOMPurify from 'dompurify';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { LifeScorePdfDownloadStartAtom, PdfDownloadStartAtom, PdfProgressAtom } from '../../../recoil/PdfDownloadAtom'

// Images
import { images } from '../../../assets/testPageImages'

// ===== chart =====
import { Doughnut } from 'react-chartjs-2';
import {
   Chart as ChartJS,
   CategoryScale,
   RadialLinearScale,
   PointElement,
   LineElement,
   ArcElement,
   LinearScale,
   Filler,
   Tooltip,
   Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import AccessToken from '../../../hooks/token/AccessToken';
import { ShowErrorMsg } from '../../../utils/ShowErrorMsg';

// Chart.js에 필요한 요소 등록
ChartJS.register(
   CategoryScale,
   RadialLinearScale,
   LinearScale,
   PointElement,
   ArcElement,
   LineElement,
   Filler,
   Tooltip,
   Legend,
   ChartDataLabels
);
// ===== chart =====

const Container = styled.div`
position: absolute;
top: -9999px;
left: -9999px;
background-color: #fff;

*{
   word-break: keep-all;
}
& *{
   background-color: transparent;
}
.green-bg-box{    
  border-radius: 16px;
  padding: 30px;
  background-color: #d9eee6;
}

.main.cover img{
  width: 100%;
}

/* 페이지 공통 */
.page-common{
  overflow: hidden;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1920px;
  /* max-width: 100%; */
  height: 2717px;
  background-color: #fff;
  &.align-top{
   align-items: flex-start;
   .main{
      justify-content: flex-start;
      padding-top: 70px;
   }
  }
}
.page-5{
   .step-item-sub-title, .tip-item{
      font-size: 22px;
   }
   .step-item-list .border-dash{
      padding: 26px 0px;
   }
}
.page-25{
   .row-group{
      gap: 16px;
   }
   .default-title{
      font-size: 33px;
   }
   .default-txt{
      font-size: 22px;
   }
   .default-txt-s{
      font-size: 20px;
   }
}
.sub-info-text-wrap{
   display: flex;
   gap: 8px;
}
.sub-info-text{
   font-size: 27px; 
   line-height: 1.55em; 
   color: #9a9a9b;
}

/* 컨텐츠 공통 */
.col-group {display:flex;}
.row-group {display:flex; flex-flow: column;}
.container { margin: 0 auto; width: 100%; padding: 9.375vw; }
.yellow { color: #fee500; }
.red { color: #d7262e; }
.orange { color: #f27255; }
.green { color: #007a10; }
.blue { color: #0088ff; }
.img-container { width: 100%; position: relative; }
.img-container img { width: 100%; height: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0; object-fit: cover; object-position: center; }
.video-container { width: 100%; padding-top: 56.25%; position: relative; }
.video-container iframe { width: 100%; height: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
::-webkit-scrollbar { width: 4px; }
::-webkit-scrollbar-track { background: #e4e4e4; }
::-webkit-scrollbar-thumb { background: #484848; border-radius: 4px;}
.null-txt { font-size: 16px; font-weight: 300; text-align: center; color: #202020; padding: 120px 0; align-items: center; gap: 16px; }
.null-txt .icon { font-size: 32px; }

.relative { position: relative; }
.sp-bt { justify-content: space-between; }
.al-ce { align-items: center; }
.gap4 { gap: 4px; }
.gap8 { gap: 8px; }
.gap16 { gap: 16px; }
.gap24 { gap: 24px; }
.gap32 { gap: 32px; }
.gap40 { gap: 40px; }
.gap48 { gap: 48px; }
.gap56 { gap: 56px; }
.half-div { width: calc( 50% - 20px ); }
.green-tip-box.w-full-size { width: 100%; }

/* 페이지 공통 */
.main {  position: relative; max-width: 1560px; width:100%; height:2716px; padding: 6.5625vw 0; }
.main{
  display: flex;
    flex-direction: column;
    justify-content: center;
}
.page-num { 
   font-size: 30px; 
   font-weight: 500; 
   color: #707070; 
   position: absolute; 
   right: -100px;
   top: 50px;
}
.main-title-wrap { margin-bottom: 64px; gap: 24px; align-items: center; }
.main-title-wrap .num { font-size: 240px; }
.main-title-group { gap: 16px; padding-bottom: 32px; position: relative; }
.main-title-group::after { content: ''; display: block; position: absolute; width: 80px; height: 4px; background: #000; left: 0; bottom: 0; }
.main-title { font-size: 64px; }
.main-sub-title { font-size: 32px; font-weight: 500; line-height: 1.25; }

.section-wrap { gap: 96px; }
.section-title-wrap { margin-bottom: 56px; gap: 32px; align-items: baseline; }
.section-title-wrap .num { width: 70px; height: 70px; background: #f27255; text-align: center; line-height: 70px; font-size: 46px; font-weight: bold; color: #fff; border-top-right-radius: 24px; border-bottom-left-radius: 24px; }
.section-title-wrap{
   margin-bottom: 40px;
}
.section-title { font-size: 50px; line-height: 1.25; }
.section-title-s { font-size: 38px; }

.default-title { font-size: 40px; font-weight: bold; }
.default-txt { font-size: 30px; font-weight: 500; line-height: 1.5; }
.default-txt * { line-height: 1.5; display: inline; }
.default-txt-s { font-size: 26px; font-weight: normal; line-height: 1.35; }
.default-txt-l { font-size: 34px; font-weight: normal; line-height: 1.35; }
.default-txt-bold { font-weight: bold; }

/* cover */
.main.cover { max-width: 100%;}
.user-info-list { position: absolute; width: 700px; left: 260px; bottom: 427px; gap: 80px; }
.user-info-item { padding-bottom: 20px; border-bottom: 1px solid #fff; }
.user-info-item .item-title { width: 200px; font-size: 36px; font-weight: 600; color: #fff; }
.user-info-item .item-txt { width: calc( 100% - 174px ); font-size: 36px; font-weight: normal; color: #fff; }

/* 목차 */
.list { max-width: 1560px; margin: 0 auto; padding: 180px 0; position: relative; }
.list::after { content: ''; position: absolute; display: block; width: 100%; height: 10px; background: #007a10; top: 70px; left: 0; right: 0; }
.list-title { width: 100%; font-size: 120px; font-weight: bold; }
.list-wrap { width: 100%; gap: 80px; padding-right: 64px; }
.list-group-title .num { width: 64px; text-align: right; font-size: 36px; font-weight: bold; color: #007a10; }
.list-group-title .txt { width: calc( 100% - 64px ); font-size: 36px; font-weight: bold; color: #007a10; }
.list-sub-item .page { width: 64px; text-align: right; font-size: 30px; font-weight: bold; line-height: 1.5;}
.list-sub-item .title {width: calc( 100% - 64px ); font-size: 30px; line-height: 1.5;}

/* 융합역량지수-01-01-마인드 인사이트 소개 */
.main.bg-bottom { position: relative; }
.main.bg-bottom::after { content: ''; display: block; position: absolute; width: 1920px; height: 940px; bottom: 0; background: #D9EEE6; left: 50%; transform: translateX(-50%); z-index: -1; }
.section-top { position: relative; padding: 40px 0; padding-left: 360px; background: #eeeeee; border-radius: 32px; margin-bottom: 90px; }
.section-top .img { position: absolute; width: 232px; left: 45px; top: -27px; }
.section-top .txt { font-size: 30px; line-height: 1.6; }

.main .step-item-wrap > div { width: 100%; }
.step-item { align-items: baseline; }
.step-item.border { padding: 32px; background: #fff; border-radius: 16px; border: 3px solid #00754a; align-items: baseline; }
.step-item-num { width: 64px; height: 64px; border-radius: 50%; text-align: center; line-height: 64px; background: #CBE2DA; font-size: 26px; font-weight: bold; color: #007a10; }
.step-item-txt-wrap { width: calc( 100% - 64px - 24px ); }
.step-item-title { font-size: 40px; font-weight: bold; color: #00754a; align-items: center; }
.step-item-txt { font-size: 26px; font-weight: 500; line-height: 1.5; }
.step-item-sub-title { font-size: 30px; font-weight: bold; line-height: 1.5; }

/* 융합역량지수-02-01-감정상태 */
.result-score-container { align-items: flex-start; }
.result-score-container .result-score-wrap { width: calc( 50% - 20px ); }
.result-score-wrap { width: 100%; border-radius: 16px; background: #fff; }
.result-score-wrap.border { padding: 56px 40px; border: 3px solid #e4e4e4; }
.result-score-txt-wrap { width: 100%; }
.result-score-container .result-score-txt-wrap { width: calc( 50% - 20px ); }
.result-score-title-wrap { margin-bottom: 32px; align-items: center; }
.result-score-title { font-size: 40px; font-weight: bold; }
.result-score-num { font-size: 40px; font-weight: 500; }
.result-score-num strong { font-size: 64px; }
.result-score { position: relative; width: 100%; height: 64px; border-radius: 32px; background: #e4e4e4; margin-bottom: 48px; }
/* .result-score .line { position: absolute; width: 0; height: 64px; border-left: 2px dashed #202020; top: 50%; transform: translateY(-50%); } */
.result-score .line { position: absolute; width: 0; height: 64px; border-left: 2px dashed #202020; top: 0; }
.result-score-gauge { position: absolute; left: 0; top: 0; bottom: 0; height: 100%; border-radius: 32px; }

.result-score-item { align-items: center; }
.result-score-item:not(:last-child) { border-bottom: 1px solid #e4e4e4; padding-bottom: 24px; margin-bottom: 24px; }
.result-score-item .icon { width: 64px; height: 64px; background-repeat: no-repeat; background-size: contain; background-position: center; }
.result-score-item:nth-child(1) .icon { background-image: url(${images.icon_result_score_01}); }
.result-score-item:nth-child(2) .icon { background-image: url(${images.icon_result_score_02}); }
.result-score-item:nth-child(3) .icon { background-image: url(${images.icon_result_score_03}); }
.result-score-item:nth-child(4) .icon { background-image: url(${images.icon_result_score_04}); }
.result-score-item .txt-group { width: calc( 100% - 64px - 24px ); }
.result-score-item .title { font-size: 24px; font-weight: 600; }
.result-score-item .txt { font-size: 26px; line-height: 1.2; }

.result-score-wrap.type1 .result-score-item:nth-child(1) .title { font-size: 26px; }
.result-score-wrap.type1 .result-score-item:nth-child(1) .txt { font-size: 30px; }
.result-score-wrap.type1 .result-score-num, 
.result-score-wrap.type1 .result-score-num *,
.result-score-wrap.type1 .result-score-item:nth-child(1) .title,
.result-score-wrap.type1 .result-score-item:nth-child(1) .txt { font-weight: bold; color: #0077ff; }
.result-score-wrap.type1 .result-score-gauge { background: #0077ff; }
.result-score-wrap.type1 .result-score-item:nth-child(1) .icon { background-image: url(${images.icon_result_score_01_active}); }
.result-score-wrap.type2 .result-score-item:nth-child(2) .title { font-size: 26px; }
.result-score-wrap.type2 .result-score-item:nth-child(2) .txt { font-size: 30px; }
.result-score-wrap.type2 .result-score-num, 
.result-score-wrap.type2 .result-score-num *,
.result-score-wrap.type2 .result-score-item:nth-child(2) .title,
.result-score-wrap.type2 .result-score-item:nth-child(2) .txt { font-weight: bold; color: #fcc200; }
.result-score-wrap.type2 .result-score-gauge { background: #fcc200; }
.result-score-wrap.type2 .result-score-item:nth-child(2) .icon { background-image: url(${images.icon_result_score_02_active}); }
.result-score-wrap.type3 .result-score-item:nth-child(3) .title { font-size: 26px; }
.result-score-wrap.type3 .result-score-item:nth-child(3) .txt { font-size: 30px; }
.result-score-wrap.type3 .result-score-num, 
.result-score-wrap.type3 .result-score-num *,
.result-score-wrap.type3 .result-score-item:nth-child(3) .title,
.result-score-wrap.type3 .result-score-item:nth-child(3) .txt { font-weight: bold; color: #ff5b36; }
.result-score-wrap.type3 .result-score-gauge { background: #ff5b36; }
.result-score-wrap.type3 .result-score-item:nth-child(3) .icon { background-image: url(${images.icon_result_score_03_active}); }
.result-score-wrap.type4 .result-score-item:nth-child(4) .title { font-size: 26px; }
.result-score-wrap.type4 .result-score-item:nth-child(4) .txt { font-size: 30px; }
.result-score-wrap.type4 .result-score-num, 
.result-score-wrap.type4 .result-score-num *,
.result-score-wrap.type4 .result-score-item:nth-child(4) .title,
.result-score-wrap.type4 .result-score-item:nth-child(4) .txt { font-weight: bold; color: #d7262e; }
.result-score-wrap.type4 .result-score-gauge { background: #d7262e; }
.result-score-wrap.type4 .result-score-item:nth-child(4) .icon { background-image: url(${images.icon_result_score_04_active}); }

.result-score-txt { padding: 40px 56px; padding-right: 205px; border-radius: 16px; background: #eee; font-size: 40px; font-weight: bold; line-height: 1.4; word-break: keep-all; position: relative; }
.result-score-txt::after { content: ''; display: block; position: absolute; width: 205px; height: 100%; background-repeat: no-repeat; background-size: contain; background-position: right bottom; bottom: 0; right: 0; }
.result-score-txt-img{
display: block; 
position: absolute; 
width: auto; 
height: 85%; 
bottom: 0; 
right: 0; 
}
.result-score-txt.type1 .color { color: #0077ff; }
.result-score-txt.type2 .color { color: #fcc200; }
.result-score-txt.type3 .color { color: #ff5b36; }
.result-score-txt.type4 .color { color: #d7262e; }

.result-score-list{margin-top:48px;}

.green-border-box { width: 100%; padding: 40px; border-radius: 16px; border: 3px solid #00754a; background: #fff; }
.green-border-box.bg-green { background-color: #d9eee6; }
.red-border-box { width: 100%; padding: 40px; border-radius: 16px; border: 3px solid #d7262e; background: #fff; }

.icon-arrow-wrap { position: absolute; width: 100%; height: 100%; display: flex; justify-content: space-evenly; align-items: center; }
.icon-arrow-both { position:relative; width: 64px; height: 40px; }
.icon-arrow-both .icon-arrow-left { width: 40px; position: absolute; left: 0; }
.icon-arrow-both .icon-arrow-right { width: 40px; position: absolute; right: 0; }

/* 융합역량지수-02-02-감정상태 */
.depression-1 { position: relative; }
.depression-1 .img { position: absolute; width: 320px; height: 425px; bottom: 0; right: 0; }
.depression-2 { position: relative; padding-left: 500px; }
.depression-2 .img { position: absolute; width: 407px; height: 694px; top: 40px; left: 40px; }

/* 융합역량지수-02-03-감정상태 */
.step-item-list.border { 
   padding: 0 32px;
    border: 3px solid #00754a; 
    border-radius: 16px; 
    background-color: #ffffff; 
   }
.page-19 .step-item-list.border,
.page-20 .step-item-list.border{
   background-color: transparent; 
}
.step-item-list .border-dash { padding: 32px 0; word-break: keep-all; border-radius: 0; align-items: baseline; }
.step-item-list .border-dash:not(:last-child) { border-bottom: 2px dashed #007a10; }

.tip-wrap > div { width: 100%; justify-content: space-between; }
.tip-wrap .img-1 { max-width: 440px; margin: 0 auto; margin-bottom: -56px; }
.tip-item-wrap { padding: 32px; border-radius: 16px; background: #d9eee6; }
.tip-item-wrap .icon { width: 64px; height: 64px; text-align: center; line-height: 64px; border-radius: 50%; background: #155845; color: #fff; font-size: 26px; font-weight: bold; }
.tip-item-group { width: calc( 100% - 64px - 24px ); }
.tip-item { font-size: 30px; font-weight: bold; color: #155845; line-height: 1.5; }
.tip-item:not(:last-child) { padding-bottom: 24px; border-bottom: 2px dashed #155845; margin-bottom: 24px; word-break: keep-all; }

/* 융합역량지수-02-04-감정상태 */
.relax-title-wrap { align-items: flex-end; }
.relax-title-wrap .img { width: 200px; height: 200px; }
.relax-title-group { width: calc( 100% - 200px - 40px ); }

/* 융합역량지수-02-05-감정상태 */
.anger-img { position: absolute; padding: 30px; right: 0; top: 20px; width: 575px; height: 505px; background: #fff; border-bottom-left-radius: 210px; }

/* 융합역량지수-02-08-감정상태 */
.compare-group { width: 100%; }
.compare-title { position: relative; padding-left: 56px; height: 184px; display: flex; flex-flow: column; justify-content: center; border-radius: 16px; overflow: hidden; }
.compare-title .img { position: absolute; width: auto; height: 184px; top: 0; right: 0; }

.compare-group:nth-child(1) .compare-title { background: #CBE3CE; }
.compare-group:nth-child(2) .compare-title { background: #D0DDDA; }
.compare-group:nth-child(2) .compare-title .default-title { color: #155845; }
.compare-group:nth-child(1) .step-item-num { background: #CBE3CE; color: #007a10; }
.compare-group:nth-child(2) .step-item-num { background: #D0DDDA; color: #155845; }

/* 융합역량지수-03-01-융합역량지능 */
.meta-q-guide { width: 100%; border-radius: 16px; border: 3px solid #e4e4e4; background: #fff; }
.meta-q-guide-item { width: 100%; padding: 24px 0; padding-left: 40px; position: relative; }
.meta-q-guide-item:not(:last-child):after { content: ''; display: block; position: absolute; width: 2px; height: calc( 100% - 48px ); background: #e4e4e4; right: 0; top: 24px; }
.meta-q-guide-item .title { font-size: 24px; font-weight: 600; }
.meta-q-guide-item .txt { font-size: 24px; }

.meta-q-chart-wrap { display: flex; gap: 40px; margin-top: 16px; }
.meta-q-chart-wrap .chart-num-wrap { display: flex; flex-direction: column; height: 480px; justify-content: space-between; width: 40px; align-items: flex-end; }
.meta-q-chart-wrap .chart-num { font-size: 24px; font-weight: 600; }
.meta-q-chart-wrap .chart-graph-wrap { display: flex; width: calc( 100% - 80px ); justify-content: space-evenly; position: relative; }
.meta-q-chart-wrap .chart-graph-wrap::after { content: ''; display: block; position: absolute; width: 100%; height: 3px; background: #e4e4e4; top: 480px; }
.meta-q-chart-wrap .chart-graph-wrap::before { content: ''; display: block; position: absolute; width: 100%; height: 0; border-top: 2px dashed #000; top: 240px; z-index: 1; }
.meta-q-chart-wrap .chart-gauge { height: 480px; width: 96px; border-top-left-radius: 8px; border-top-right-radius: 8px; background: #f5f5f5; position: relative; }
.meta-q-chart-wrap .chart-gauge-core { position: absolute; width: 100%; border-top-left-radius: 8px; border-top-right-radius: 8px; bottom: 0; left: 0; right: 0; background: #aaaaaa;}
.meta-q-chart-wrap .chart-graph-item.red .chart-gauge-core { background: #d7262e; }
.meta-q-chart-wrap .chart-graph-item.green .chart-gauge-core { background: #00754a; }
.meta-q-chart-wrap .chart-gauge-num { position: absolute; font-size: 24px; font-weight: 600; left: 50%; transform: translate(-50%, -100%); top: -8px; }
.meta-q-chart-wrap .chart-txt-group { margin-top: 24px; align-items: center; }
.meta-q-chart-wrap .chart-txt-group .txt { font-size: 24px; font-weight: 600; }
.meta-q-chart-wrap .chart-graph-item.red .chart-txt-group .txt { color: #d7262e; }
.meta-q-chart-wrap .chart-graph-item.green .chart-txt-group .txt { color: #00754a; }
.meta-q-icon { background-repeat: no-repeat; width: 56px; height: 56px; background-size: contain; background-position: center; }
.meta-q-icon>img{ width:100%; height:100%; object-fit:cover;}

.meta-q-result-group > div { width: 100%; }
.step-item-list.border.red { border: 3px solid #d7262e; }
.step-item-list.red .border-dash { border-color: #d7262e; }
.step-item-list.red .step-item-num { background: #F7D3D5; color: #d7262e; }

/* 융합역량지수-03-02 강점/약점보완영역 */
.default-title .icon { width: 56px; }

/* 융합역량지수-03-03-융합역량지능 */
.circle-chart {margin:0; padding: 0; }
.circle-chart .label { text-anchor: middle; width: 100%; font-size: 24px; font-weight: 500; }
// .circle-chart svg { width:600px; height: 600px;}

/* .circle-chart { width: 100%; height: 674px; }
.circle-chart .label { text-anchor: middle; width: 100%; font-size: 24px; font-weight: 500; } */

/* 융합역량지수-04-01 에너지 역량 */
.step-item-list.per-type> .col-group { justify-content: space-between; align-items: center; }
.step-item-list.per-type .step-item { width: 542px; }
.step-item-list.per-type .step-item:last-child .step-item-num { background: #D0DDDA; color: #155845; }
.step-item-list.per-type .step-item:last-child .green { color: #155845; }
.step-item-list.per-type .icon-arrow { font-size: 36px; color: #aeaeae; transform: rotate(-90deg); }
.default-txt.center { text-align: center; }

/* 융합역량지수-04-02 에너지 역량 */
.energy-result-wrap { display: flex; flex-flow: column; gap: 80px; }
.energy-result-top { display: flex; align-items: center; justify-content: space-between; }
.energy-result-top-title { display: flex; align-items: center; gap: 24px; }
.energy-result-top-title .num { width: 64px; height: 64px; border: 3px solid #aaaaaa; background: #fff; text-align: center; line-height: 58px; font-size: 26px; font-weight: 500; color: #aaaaaa; border-radius: 50%; }
.energy-result-top-title .title { font-size: 32px; font-weight: 600; color: #aaaaaa; text-transform: uppercase; }
.energy-guage-wrap { position: relative; width: 960px; height: 64px; border-radius: 32px; border: 3px solid #202020; background: #eee; }
.energy-guage { position: absolute; width: 50%; height: 100%; top: 0; bottom: 0; }
.energy-guage.left { right: 0; transform: translateX(-100%); }
.energy-guage.right { left: 0; transform: translateX(100%); }
.energy-guage-bar { position: absolute; height: 100%; top: 0; bottom: 0; background: #aaaaaa; }
.energy-guage.left .energy-guage-bar { right: 0; border-top-left-radius: 32px; border-bottom-left-radius: 32px; }
.energy-guage.right .energy-guage-bar { left: 0; border-top-right-radius: 32px; border-bottom-right-radius: 32px; }
.energy-guage-num-group { width: 100%; display: flex; justify-content: space-between; position: absolute; bottom: -32px; left: 0; right: 0; } 
.energy-guage-num { position: relative; font-size: 24px;  }
.energy-guage-num:not(:first-child, :last-child)::after { content: ''; display: block; position: absolute; width: 0; height: 60px; border-left: 2px dashed #202020; top: -66px; left: 50%; transform: translateX(-50%); }

.energy-result-wrap .step-item.border { border-color: #303030; }
.energy-result-wrap .step-item-num { background-color: #aeaeae; color: #202020; text-transform: uppercase; font-size: 32px; }

.energy-result-wrap.left .energy-result-top-title.left .num,
.energy-result-wrap.right .energy-result-top-title.right .num { border-color: #00754a; background-color: #00754a; color: #fff; }
.energy-result-wrap.left .energy-result-top-title.left .title,
.energy-result-wrap.right .energy-result-top-title.right .title,
.energy-result-wrap.left .step-item.left .default-title,
.energy-result-wrap.right .step-item.right .default-title { color: #00754a; }
.energy-result-wrap.left .energy-guage.left .energy-guage-bar,
.energy-result-wrap.right .energy-guage.right .energy-guage-bar { background: #007a10; }
.energy-result-wrap.left .step-item.left, 
.energy-result-wrap.right .step-item.right { border-color: #00754a; }
.energy-result-wrap.left .step-item.left .step-item-num, 
.energy-result-wrap.right .step-item.right .step-item-num { background-color: #CBE2DA; color: #00754a; }

.default-txt.dot { position: relative; padding-left: 20px; }
.default-txt.dot::after { content: ''; display: block; position: absolute; width: 6px; height: 6px; border-radius: 50%; background: #333; left: 0; top: 19px; }
.default-txt-s.dot::after { width: 4px; height: 4px; top: 15px; }
.default-txt-l.dot::after { top: 21px; }
.default-txt.green.dot::after { background: #007a10; } 

.energy-result-top-wrap { padding: 40px; padding-top: 56px; border: 3px solid #00754a; border-radius: 16px; }
.energy-result-title-wrap { display: flex; justify-content: space-between; align-items: baseline; padding-bottom: 36px; border-bottom: 3px solid #00754a; }
.energy-result-type { font-size: 56px; font-weight: bold; color: #00754a; }
.energy-result-top-wrap .energy-result-wrap { padding: 24px 0 60px; }
.energy-result-top-wrap .energy-result-wrap:not(:last-child) { border-bottom: 1px solid #e4e4e4; }
.energy-result-top-wrap .energy-result-wrap:last-child { padding-bottom: 24px; }
.energy-result-top-wrap .energy-guage-wrap { width: 800px; }
.energy-result-top-wrap .energy-result-top-title { width: calc( ( 100% - 800px ) / 2 ); }
.energy-result-top-wrap .energy-result-top-title.right { justify-content: flex-end; }

/* 241127 추가 */
.personality-type-wrap { position: relative; background: #d9eee6; border-radius: 16px; padding: 80px 0; z-index: 0; }
.personality-type-wrap .line { position: absolute; width: 961px; height: 268px; bottom: 102px; left: 50%; transform: translateX(-50%); z-index: -1; }
.personality-type-group { gap: 95px; justify-content: center; align-items: flex-end; }
.personality-type-item { display: flex; flex-flow: column; gap: 16px; align-items: center; }
.personality-type-item:nth-child(2n-1) { margin-bottom: 180px; }
.personality-type-item .txt-group { gap: 32px; text-align: center; }
.personality-type-item .txt { font-size: 30px; line-height: 1.2; }
.personality-type-item .title { position: relative; }
.personality-type-item .title::after { content: ''; display: block; position: absolute; width: 30px; height: 5px; background: #00754a; bottom: -16px; left: 50%; transform: translate(-50%, 50%); }
.personality-type-item .img { width: 240px; }

/* 융합역량지수-04-04 에너지 역량 */
.balance-chart-wrap { position: relative; width: 100%; height: 270px; }
.balance-chart-bar { position: absolute; width: 520px; height: 84px; top: 20px; left: 50%; transform: translateX(-50%); background-repeat: no-repeat; background-size: contain; background-position: center bottom; }
.balance-chart-bar.left { background-image: url(${images.balance_left}); }
.balance-chart-bar.right { background-image: url(${images.balance_right}); }
.balance-chart-bar.center { background-image: url(${images.balance_center}); }
.balance-chart-core { position: absolute; width: 180px; height: 100%; bottom: 0; left: 50%; transform: translateX(-50%); }
.balance-chart-group { height: 100%; justify-content: space-between; align-items: flex-end; padding: 0 17px; }
.balance-chart { width: 160px; height: 160px; position: relative; border-radius: 50%; background: #bbbbbb;}
.balance-chart.active { background: #007a10; }
.balance-chart-gauge { position: absolute; width: 100%; height: 100%; border-radius: 50%; }
.balance-chart-circle { position: absolute; width: 80px; height: 80px; background: #fff; border-radius: 50%; left: 50%; top: 50%; transform: translate(-50%, -50%); text-align: center; line-height: 80px; font-size: 40px; font-weight: bold; color: #707070; }
.balance-chart.active .balance-chart-circle { color: #007a10; }
.balance-chart .title { font-size: 26px; font-weight: bold; color: #707070; text-align: center; position: absolute; bottom: -40px; width: 100%; }
.balance-chart.active .title { color: #007a10; }
.balance-chart-txt-wrap { padding-top: 32px; border-top: 3px solid #e4e4e4; margin-top: 90px; }

.step-item.active { position: relative; }
.step-item.active::after { content: ''; display: block; position: absolute; width: calc( 100% + 64px ); height: 100%; background: #D5E8E1; top: 0; left: -32px; z-index: -1; }
.step-item.active .step-item-num { background: #ffffff; }
.step-item.active .default-txt { font-weight: bold; }

/* 융합역량지수-05-03-힘의 균형 */
.prescription { 
   position: relative;
   padding-right: 480px; 
}
.prescription::after{
   content: '';
   display: block;
   clear: both;
   position: absolute;
   bottom: 0px;
   right: 0px;
   width: 350px;
   height: 100%;
   background-image: url(${images.prescription}); 
   background-size: cover;
   background-repeat: no-repeat; 
}

/* 융합역량지수-05-05-힘의 균형 */
.wing-type-section { position: relative }
.wing-type-section::after { 
   content: ''; 
   display: block; 
   width: 1920px; 
   height: calc( 100% + 104px + 6.5625vw ); 
   background: #d9eee6;
   position: absolute; 
   top: -104px; 
   z-index: -1; 
   left: -180px;
   }
.wing-type-group { padding: 24px 0; gap: 120px; position: relative; z-index: 0; }
.wing-type-group:not(:last-child) { border-bottom: 2px dashed #00754a; }
.wing-type-group:not(:last-child)::after { content: ''; display: block; position: absolute; width: 3px; height: 100%; background: #00754a; left: 50%; top: 50%; transform: translateX(-50%); z-index: -1; }
.wing-type-group .num { position: absolute; width: 64px; height: 64px; border-radius: 50%; border: 3px solid #00754a; text-align: center; line-height: 58px; background: #fff; font-size: 24px; font-weight: bold; color: #00754a; left: 50%; top: 50%; transform: translate(-50%, -50%); }
.wing-type-item { width: calc( 50% - 60px ); }
.wing-type-item:first-child { align-items: flex-end; text-align: right; }

/* 융합역량지수-06-01 관계와 소통 분석 */
.ego-result-item { width: 100%; height: 135px; border-radius: 20px; border: 1px solid #e1e1e1; background: #fff; align-items: center; justify-content: center; }
.ego-result-item .icon { width: 64px; height: 64px; object-fit: contain; object-position: center; }
.ego-result-chart-wrap { align-items: center; }
.ego-result-chart-group { width: calc( 100% - 260px ); }
.ego-result-chart { height: 290px; }
.ego-result-score-wrap { width: 220px; border-radius: 20px; border: 3px solid #e4e4e4; background: #fff; }
.ego-result-score-item { padding: 24px 40px; }
.ego-result-score-item .title { font-size: 24px; font-weight: 600; }
.ego-result-score-item .txt { font-size: 24px; font-weight: normal; }
.ego-result-title-group { justify-content: space-between; padding-left: 132px; padding-right: 96px; }
.ego-result-title-group .default-txt { color: #707070; }
.ego-result-txt-group { justify-content: space-between; padding-left: 116px; padding-right: 74px; }
.ego-result-txt-group .default-txt-s { text-align: center; font-size: 24px; font-weight: 600;}

/* 융합역량지수-07-01 인생점수 */
.age-wrap { flex-flow: wrap; }
.age-item { width: calc( ( 100% - 16px * 3 ) / 4 ); padding: 20px 30px; border: 2px solid #dedede; background: #fff; border-radius: 20px; }
.age-item .icon { width: 130px; height: 130px; }
.default-txt-ss { font-size: 24px; font-weight: normal; line-height: 1.2; }
.default-txt.right { text-align: right; }
.age-score-wrap { gap: 2px; }
.age-score { width: calc( ( 100% - 2px * 9 ) / 10 ); height: 20px; border-radius: 10px; background: #eee; }
.age-score.active { background: #f27255; }

.life-score-section { position: relative; padding-top: 120px; padding-bottom: 710px; }
.life-score-section::after { 
   content: ''; 
   display: block; 
   width: 1920px; 
   height: calc( 100% + 6.5625vw ); 
   background-color: #fff0ee; 
   position: absolute; 
   bottom: -6.5625vw; 
   z-index: -1; 
   left: -180px
}
.life-score-section::before { 
   content: ''; 
   display: block; 
   width: calc(100% + 360px); 
   height: 645px;
   position: absolute; 
   bottom: 0px;
   left: -180px;
   z-index: 0; 
   background-size: 1920px; 
   background-repeat: no-repeat;
}
.life-score-title-wrap { margin-bottom: 40px; align-items: flex-end; }
.life-score-section.age0::before { background-image: url(${images.life_score_section_0}); }
.life-score-section.age1::before { background-image: url(${images.life_score_section_1}); }
.life-score-section.age2::before { background-image: url(${images.life_score_section_2}); }
.life-score-section.age3::before { background-image: url(${images.life_score_section_3}); }
.life-score-section.age4::before { background-image: url(${images.life_score_section_4}); }
.life-score-section.age5::before { background-image: url(${images.life_score_section_5}); }
.life-score-section.age6::before { background-image: url(${images.life_score_section_6}); }
.life-score-section.age7::before { background-image: url(${images.life_score_section_7}); }
.life-score-title-wrap .img { width: 56px; }
.life-score-title { font-size: 48px; font-weight: bold; color:#000 }
.life-score-result-wrap { padding: 72px; border-radius: 20px; background: #ffffff; }
.life-score-result-wrap> .row-group { width: calc( 100% - 480px - 56px ); }
.life-score-result-wrap .title-wrap { padding-bottom: 56px; border-bottom: 2px solid #dedede; }
.life-score-result-wrap .title-wrap .icon { width: 130px; height: 130px; background-repeat: no-repeat; background-size: contain; background-position: center; }
.life-score-section.age0 .life-score-result-wrap .title-wrap .icon { background-image: url(${images.age_icon_0_on}); }
.life-score-section.age1 .life-score-result-wrap .title-wrap .icon { background-image: url(${images.age_icon_1_on}); }
.life-score-section.age2 .life-score-result-wrap .title-wrap .icon { background-image: url(${images.age_icon_2_on}); }
.life-score-section.age3 .life-score-result-wrap .title-wrap .icon { background-image: url(${images.age_icon_3_on}); }
.life-score-section.age4 .life-score-result-wrap .title-wrap .icon { background-image: url(${images.age_icon_4_on}); }
.life-score-section.age5 .life-score-result-wrap .title-wrap .icon { background-image: url(${images.age_icon_5_on}); }
.life-score-section.age6 .life-score-result-wrap .title-wrap .icon { background-image: url(${images.age_icon_6_on}); }
.life-score-section.age7 .life-score-result-wrap .title-wrap .icon { background-image: url(${images.age_icon_7_on}); }
.life-score-result-wrap .title-wrap .score { font-size: 56px; font-weight: bold; color: #f27255; }
.life-score-result-wrap .txt-group .default-txt { position: relative; }
.life-score-result-wrap .txt-group .default-txt:not(:last-child)::after { content: ''; display: block; position: absolute; width: 1px; height: 30px; background: #bfbfbf; right: -16px; top: 50%; transform: translate(-50%, -50%); }
.life-score-result-wrap .chart { position: relative; width: 480px; height: 480px; border-radius: 50%; background: #ffd3cb; }
.life-score-result-wrap .chart-txt { display: block; position: absolute; width: 320px; height: 320px; background: #fff; left: 50%; top: 50%; transform: translate(-50%, -50%); border-radius: 50%; text-align: center; line-height: 320px; font-size: 72px; font-weight: bold; color: #f27255; }

/* 융합역량지수-07-02 인생점수 */
.green-border-box.center { text-align: center; align-items: center; padding: 32px; }
.icon-arrow-down { width: 40px; }
.icon-arrow-right { transform: rotate(-90deg); }
.icon-arrow-left { transform: rotate(90deg); }
.green-tip-box { 
   width: 50%; 
   padding: 32px 24px; 
   background: #ffe3de; 
   border-radius: 16px; 
   align-items: baseline; 
}
.green-tip-box.center { text-align: center; align-items: center; }
.green-tip-box .icon { width: 64px; height: 64px; border-radius: 50%; background: #bb2200; text-align: center; line-height: 64px; font-size: 26px; font-weight: bold; color: #fff; }
.green-tip-box .default-txt { color: #f27255; }
.green-tip-box .default-txt .green { 
   color: #bb2200
}
.recons-img { width: 480px; }

/* 융합역량지수-08-01 metaQ */
.result-score.number { position: relative; margin-bottom: 88px; }
.result-score-num-wrap { position: absolute; bottom: -40px; width: 100%; justify-content: space-between; }
.result-score-num-wrap .num { font-size: 24px; transform: translateX(50%); }
.result-score-num-wrap .num:last-child { transform: translateX(0); }
.result-score-item .num { width: 64px; height: 64px; border: 4px solid #aaa; background: #fff; text-align: center; line-height: 56px; font-size: 24px; font-weight: 600; color: #aaa; border-radius: 50%; }
.result-score-wrap.type1 .result-score-item:nth-child(1) .num { border-color: #0077ff; color: #fff; background: #0077ff; }
.result-score-wrap.type2 .result-score-item:nth-child(2) .num { border-color: #fcc200; color: #fff; background: #fcc200; }
.result-score-wrap.type3 .result-score-item:nth-child(3) .num { border-color: #ff5b36; color: #fff; background: #ff5b36;}
.result-score-wrap.type4 .result-score-item:nth-child(4) .num { border-color: #d7262e; color: #fff; background: #d7262e; }

/* 융합역량지수-08-02-metaQ */
/* .strategy-1 { background-image: url(${images.growth_strategy_01}); background-repeat: no-repeat; background-size: 408px; background-position: bottom right 20px; } */
.strategy-1 { position:relative; }
.strategy-1::after{
   background-image: url(${images.growth_strategy_01});
}
.strategy-2 { position:relative; }
/* .strategy-2 { background-image: url(${images.growth_strategy_02}); background-repeat: no-repeat; background-size: 408px; background-position: bottom right 20px; } */
.strategy-2::after{background-image: url(${images.growth_strategy_02});}
.strategy-1::after, .strategy-2::after{
   content: "";
   display: block;
   clear: both;
   position: absolute;
   bottom: 0px;
   right: 20px;
   width: 400px;
   height: 315px;
   background-repeat: no-repeat;
   background-size: cover;
}

/* 융합역량지수-08-03 나의 강점 */
.section-top-08 .img { width: 258px; top: unset; bottom: 0; }
.letter-wrap { width: 1120px; margin: 0 auto; background: #fff; border-radius: 20px; border: solid 3px #eee; padding: 0 104px; padding-bottom: 560px; padding-top: 95px; position: relative; }
.letter-wrap::after { content: ''; display: block; width: 1280px; height: 685px; background-image: url(${images.letter_cover}); background-repeat: no-repeat; background-size: 100% 100%; position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); }
.letter-wrap::before { content: ''; display: block; width: 1885px; height: 905px; background-image: url(${images.letter_bg}); background-repeat: no-repeat; background-size: 100% 100%; position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); }
.letter-title-wrap { margin-bottom: 56px; text-align: center; }
.letter-sub-title { font-size: 30px; color: #9acc9a; }
.letter-title { font-size: 56px; font-weight: bold; }
.letter-item { justify-content: space-between; padding: 16px 0; border-bottom: 2px solid #00754a; min-height: 80px; }
.letter-item.min { max-width: 1088px; }

/* 융합역량지수-08-04 강점리스트 */
.check-list { flex-flow: wrap; gap: 16px; }
.check-item { width: calc( ( ( 100% - 16px * 4 ) / 5) ); padding: 22px 0; align-items: center; }
.check-icon { width: 36px; height: 36px; border-radius: 4px; border: 1px solid #707070; background: #fff; }
.check-txt { font-size: 30px; }

.input-box { width: 100%; height: 88px; border: 2px solid #c9c9c9; background: #fff; border-radius: 4px; position: relative; }
.input-box .icon { font-size: 40px; color: #00754a; position: absolute; left: 24px; top: 50%; transform: translateY(-50%); }
.example-box { padding: 30px; border-radius: 8px; background: #f5f5f5; }
.example-box .label { width: 64px; height: 36px; border-radius: 4px; background: #202020; color: #fff; text-align: center; line-height: 36px; font-size: 24px; margin-bottom: 8px; }

/* 융합역량지수-08-05 핵심가치 리스트 */
.check-list-wrap { gap: 40px; flex-flow: wrap; }
.check-list-wrap .green-border-box { width: calc( ( 100% - 80px ) / 3 ); padding: 32px; }
.check-list-title { height: 60px; line-height: 60px; border-radius: 30px; width: fit-content; padding: 0 32px; background: #CBE2DA; font-size: 30px; font-weight: bold; margin: 0 auto; }
.check-list-wrap .check-list { gap: 24px 0; }
.check-list-wrap .check-item { width: 50%; padding: 0; }
.check-list-wrap .check-item.wide { width: 100%; }
.al-en { align-items: flex-end; }

/* 융합역량지수-08-06-셀프코칭 */
.empty-box-wrap { align-items: flex-start; }
.empty-box { width: calc( 100% - 40px ); height: 188px; }

.r-bg-img{
   position: absolute;
   bottom: 0px;
   left: 0px;
   width: 100%;
   height: auto;
}
`

function LifeScorePageContainer() {

   const navigate = useNavigate();
   const accessToken = AccessToken();

   // Recoil
   const isPdfDownloadStart = useRecoilValue(LifeScorePdfDownloadStartAtom);
   const resultPageData = isPdfDownloadStart.resultPageData ? isPdfDownloadStart.resultPageData : null
   const setPdfDownload = useSetRecoilState(PdfProgressAtom);
   const setPdfDownloadStart = useSetRecoilState(PdfDownloadStartAtom);

   // 페이지 리스트
   const printPageRefs = Array.from({ length: 5 }, () => React.createRef());

   // ===== Chart =====

   // chart 4 
   const chartValue2 = resultPageData.life_average;
   const chartData2 = {
      datasets: [
         {
            data: [chartValue2, 100 - chartValue2],
            backgroundColor: ["#f35835", "rgba(0,0,0,0)"],
            borderWidth: 0,
            borderRadius: 40,
         },
      ],
   };

   const chartOptions2 = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: '66.67%',
      hover: { mode: null },
      plugins: {
         legend: {
            display: false,
         },
         tooltip: {
            enabled: false,
         },
         datalabels: {
            display: false, // 데이터 레이블 플러그인 비활성화
         },
      },
      animation: {
         duration: 0, // 애니메이션 비활성화
      },
   };
   // ===== chart End =====

   // 데이터 계산
   // ref[29]
   const ref29ScoreType = resultPageData.life_average < 50 ? '50' : resultPageData.life_average < 61 ? '60' : resultPageData.life_average < 71 ? '70' : resultPageData.life_average < 81 ? '80' : resultPageData.life_average < 91 ? '90' : '91'
   const ref29ScoreResult = {
      50: {
         review: '당신은 지금 인생에 대한 총체적인 점검과 성찰을 해야 하는 시간입니다. 당신 내면의 ‘불만’은 ‘불안’을 만들고, 불안은 ‘분노’ 또는 ‘우울’로 표현됩니다.',
         remember: '<span class="orange">변화는 반응이 아니라 이제 선택해야 하는 현실이 되었습니다.</span> 우리는 삶의 위기, 트라우마, 상실, 비극, 질병 등을 경험하고 나서야 변화의 길을 모색합니다. 사람은 잘 변하지 않습니다. 그래서 ‘사람이 변하면 죽을 때가 되었나’ 라고들 말하기도 합니다. 사람은 인생의 커다란 위기의 순간, 내가 누구인지, 내가 무엇을 하고 있는지, 어떻게 살아가는지, 어떤 것을 의지하고, 믿고 따르고 있는지, 어떤 생각과 감정을 느끼고 있는지 성찰(Reflection)하게 됩니다. <br/><br/>마음을 바꾸고자 하지만 그것이 쉽지는 않습니다. 내가 가지고 있는 믿음의 뿌리는 나를 둘러싸고 있던 종교, 문화, 사회, 교육, 가족, 매스미디어 등 과거에 주입되거나 학습된 수많은 패러다임들입니다. 시대가 변하고 있습니다. 우리가 알고 있는 것보다 현실은 더 넓고 깊고 빠르게 변화하고 있습니다. 그럼에도 ‘자신만이 옳다’라고 생각하면 당신은 변화할 수 없습니다. 내가 가지고 있는 생각이 편협할 수도 있고, 나의 판단이 잘못될 수도 있습니다. <span class="orange">‘내가 틀릴 수도 있다’라는 생각이 당신 변화의 시작이어야 합니다.</span>',
         reinterpretation: '마음의 5%만이 의식적이며 나머지 95%는 무의식적인 프로그램, 기억된 행동, 습관적 감정 반응에서 나온다고 합니다. <span class="orange">우리는 하루에 95%가량을 무의식적인 상태로 보내는 것입니다. 겉으로는 깨어있는 것 같지만, 무의식이 나를 움직이고 있는 것입니다.</span> 내가 무슨 생각을 하고, 무슨 행동을 하며, 어떤 감정을 느끼는지 더이상 알아차리지 못한다면 잘못된 삶은 습관이 되어버립니다. <span class="orange">우리가 깨야 하는 가장 큰 것은 우리 자신이 되어버린 습관이라는 무서운 ‘틀’, 자동적 사고입니다.</span> 진짜 주인인 의식은 잠들어 버리고 무의식이 몸을 지배합니다. <br/><br/>무의식은 기억된 감정들과 일치하는 결정을 내리도록 명령하고 있는 몸입니다. 몸이 마음을 지배하고 있는 것입니다. 중독이 되어버린 몸이 마음을 지배해버립니다. 이제 우리는 이 과정을 통해 잠재의식(무의식) 안으로 들어가 그것을 새롭게 하는 프로그래밍 작업을 해야합니다. <span class="orange">낡은 생각과 느낌의 패턴을 찾아 끊어 버리고, 우리가 되고자 하는 모습에 맞추어 새로운 생각과 감정의 패턴을 재학습 혹은 재연결해야 합니다.</span> 몸은 새로운 마음에 길들이면 더 이상 반대로 작용하지 않고 조화를 이루게 됩니다. 이것이 변화의 핵심이자 자기 창조(Recreation)의 핵심이며 재해석의 포인트입니다. ',
         refresh: '그동안 내가 불안하고, 우울하고, 분노했던 기억들을 톺아보면 대부분 지나간 시간에 대한 후회와 원망, 일어나지도 않은 미래의 일에 대한 걱정과 불안이 지배적이었다는 것을 깨닫게 됩니다. 존재하지 않는 과거와 다가오지 않은 미래가 소중한 현재, 지금 여기를 병들에 하고, 멈추게 하고, 좌절하게 만들거나 포기하게 만들었던 것입니다. 그래서 중요한 방법이 <span class="orange">‘지금여기’에 집중하는 것, 몰입하는 것입니다.</span><br/><br/> 내가 행복해 하는 시간은 부쩍 빨리 지나가 버립니다. 운전을 하거나, 영화를 보거나, 좋은 사람과 좋은 시간을 보내는 것에 몰입하면 시간을 잊어버립니다. 자연 속에 고요히 앉아 있거나, 조용하고 쾌적한 공간에서 명상하는 시간 등의 몰입은 지금 여기의 소중한 가치를 일깨워줍니다. <span class="orange">현재에 몰입하는 체험은 우리의 과거를 내려놓고, 미래의 새로운 마음을 창조할 수 있는 좋은 기회가 될 수 있습니다.</span>',
         reformation: [
            {
               name: '마음 읽기(독심술)',
               content: '다른 사람에 대한 충분한 근거 없이, ‘저 사람은 이러저러한 생각을 할 것이다’라고 가정한다. ',
               result: '“그는 내가 실패자라고 생각할 것이다.”',
               tip: '인지 왜곡이 일어날 수 있는, 당신 안에 부정적인 신념을 구분하고 분류해 보는 것이 좋습니다.',
            },
            {
               name: '점치기(예언)',
               content: '미래를 예측한다. ‘미래에 어떤 위험이나 재앙이 있을 것이다’라고 생각한다.',
               result: '“나는 분명 이번 시험에 떨어질 것이다.”',
               tip: '혹시 당신은 다른 사람들의 마음을 먼저 이렇게 생각하지는 않는가요?',
            },
         ],
         reformation_desc: [
            '우리는 때때로 다른 사람의 생각이나 미래의 결과를 정확히 알지 못하면서도 추측하고 단정 짓는 습관을 갖고 있습니다. 이는 마음 읽기나 점치기라는 인지 왜곡으로, 자신을 부정적인 감정이나 스트레스에 빠뜨릴 수 있습니다. 이러한 습관을 바로잡기 위해 자신의 생각을 인식하고, 더 합리적이고 긍정적인 사고로 전환하는 연습이 필요합니다.',
            '이러한 생각의 고리들이 당신을 자꾸 수렁으로 밀어 넣고 있는 것입니다. <span class="orange">내 안에서 만들어 놓은 타인과 자신에 대한 부정적인 생각의 고리들을 끊어내야 합니다. 그래야 다시 시작할 수 있습니다.</span> 용기를 내어 인생재건축을 시작해봅니다. 정확한 자기분석은 인생을 변화하는 첫걸음이 될 것입니다.'
         ],
         reconstruction: '나도 모르게 ‘나는 부적합한 사람이며 타인에게 거절당할 지도 모른다’는 두려움을 갖게 됩니다. 이와 동시에 ‘실패’에 대한 두려움에 내 능력을 제대로 발휘하지도 못하고, 창피당할 것이 두려워 감정을 숨기는 일이 많았습니다. 이 점은 스스로에 대한 과도한 기대 때문에 시작된 것일 수 있습니다. <span class="orange">더 잘해야 하고, 더 유능해 보여야 하고, 더 완벽해야 한다는 기대가 타인과 나를 비교하고 자꾸만 외부 시선을 의식하게 합니다.</span> <br/><br/>다른 사람의 칭찬을 얻기 위해 나 자신의 모습을 저버리는 일을 그만두어야 합니다. 그리고 용기를 내어 <span class="orange">‘내 모든 감정은 나의 것이며 나의 생각과 말과 행동은 타인의 인정과 감사를 받기 위해서가 아니라 내가 진정 원하기 때문’임을 선언해야 합니다.</span> <br/><br/>"나는 과거의 상처를 통해 배우고, 그것을 딛고 나 자신만의 길을 걸어갈 용기를 선택할 것입니다."'
      },
      60: {
         review: '당신은 일부러라도 시간을 내어 인생에 대한 점검을 해야하는 시기입니다. 당신 내면의 ‘불만’은 ‘불안’을 만들고, 불안은 ‘분노’ 또는 ‘우울’로 표현됩니다.',
         remember: '<span class="orange">당신의 기억 속에 있는 부정적인 감정들을 이해하는 작업을 해야 합니다.</span> 누구에게나 삶의 기회는 단 한 번 주어지지만, 그 삶을 바라보는 각자의 방식에 따라 서로 다른 길을 가게 됩니다. 삶에 대한 긍정과 낙관은 다가오는 일들에 대한 희망과 신뢰를 가지고 나의 에너지를 결집하여 효과적인 일처리와 성공적인 결과를 만들어 낼 수 있습니다. 그러나 부정적인 인식과 그릇된 믿음은 당신을 더욱 더 난처한 상황으로 이끌어 갈 것입니다. <span class="orange">변화는 당신에게 선택의 문제가 아니라 ‘용기’의 문제입니다.</span> <br/><br/>과거 불편한 일들이나 불편했던 사람들에 대한 기억을 해결하지 않은 채 그대로 지나고서는 현재의 ‘나’를 충만하게 살아갈 수 없습니다. 현재의 ‘나’를 변화시키지 못한다면 미래의 ‘나’ 역시 다르지 않은 그대로의 모습일 것입니다. <span class="orange">먼저 당신이 해야 할 일은 지난 기억 속에 있는 부정적인 ‘나’의 모습을 발견하고 생각을 변화시킬 수 있는 프로그램에 참여하거나, 스스로 자신을 분석하고 치유하는 마음돌봄 작업을 시작하는 것입니다.</span>',
         reinterpretation: '당신에게 있는 수치심, 죄의식, 무기력과 슬픔, 두려움에 대해 점검해 보아야 합니다. 나에게 일어났던 비참했던 기억들, 억울했던 사건이나, 나에게 해를 입힌 가해자에 대한 원망들을 돌아보세요. <br/><br/>당신이 이루지 못했던 것들로 인해 가지게 된 무기력과 슬픔을 기억하세요. 절망하고, 포기하고, 희망이 없었던 시간을 생각해 보세요. 심한 무기력과 우울감에 무력해지고, 움직이기조차 어려웠던 시간이 있었나요? 아무도 나를 이해 해주지 않는 것 같아서 답답하고 괴로웠던 시간이 있었나요? <br/><br/>그것은 당신 탓이 아닙니다. 당신만의 문제는 아닙니다. <span class="orange">당신 인생의 ‘불만’은 ‘불안’을 야기합니다. 그 불안이 밖으로 터져 오르면 ‘분노’가 되고, 안으로 묶어두면 ‘우울’이 됩니다. 이제 지난 시간의 부정적인 기억을 정돈하는 작업이 필요합니다.</span> 당신 감정의 균형을 되찾기 위해 노력해야 하는데 그 시작은 부정적인 과거의 사슬에서 벗어나려는 노력일 것입니다. 그래서 인생을 재해석(Reinterpretation)하는 작업이 필요합니다.',
         refresh: '그동안 내가 불안하고, 우울하고, 분노했던 기억들을 톺아보면 대부분 지나간 시간에 대한 후회와 원망, 일어나지도 않은 미래의 일에 대한 걱정과 불안이 지배적이었다는 것을 깨닫게 됩니다. <span class="orange">존재하지 않는 과거와 다가오지 않은 미래가 소중한 현재, 지금 여기를 병들게 하고, 멈추게 하고, 좌절하게 만들거나 포기하게 만들었던 것입니다. 그래서 중요한 방법이 ‘지금여기’에 집중하는 것, 몰입하는 것입니다.</span> <br/><br/>내가 행복해 하는 시간은 부쩍 빨리 지나가 버립니다. 운전을 하거나, 영화를 보거나, 좋은 사람과 좋은 시간을 보내는 것에 몰입하면 시간을 잊어버립니다. 자연 속에 고요히 앉아 있거나, 조용하고 쾌적한 공간에서 명상하는 시간 등의 몰입은 지금 여기의 소중한 가치를 일깨워줍니다. 현재에 몰입하는 체험은 우리의 과거를 내려놓고, 미래의 새로운 마음을 창조할 수 있는 좋은 기회가 될 수 있습니다. ',
         reformation: [
            {
               name: '마음 읽기(독심술)',
               content: '다른 사람에 대한 충분한 근거 없이, ‘저 사람은 이러저러한 생각을 할 것이다’라고 가정한다. ',
               result: '“그는 내가 실패자라고 생각할 것이다.”',
               tip: '인지 왜곡이 일어날 수 있는, 당신 안에 부정적인 신념을 구분하고 분류해 보는 것이 좋습니다.',
            },
            {
               name: '점치기(예언)',
               content: '미래를 예측한다. ‘미래에 어떤 위험이나 재앙이 있을 것이다’라고 생각한다.',
               result: '“나는 분명 이번 시험에 떨어질 것이다.”',
               tip: '혹시 당신은 다른 사람들의 마음을 먼저 이렇게 생각하지는 않는가요?',
            },
         ],
         reformation_desc: [
            '우리는 때때로 다른 사람의 생각이나 미래의 결과를 정확히 알지 못하면서도 추측하고 단정 짓는 습관을 갖고 있습니다. 이는 마음 읽기나 점치기라는 인지 왜곡으로, 자신을 부정적인 감정이나 스트레스에 빠뜨릴 수 있습니다. 이러한 습관을 바로잡기 위해 자신의 생각을 인식하고, 더 합리적이고 긍정적인 사고로 전환하는 연습이 필요합니다.',
            '이러한 생각의 고리들이 당신을 자꾸 수렁으로 밀어 넣고 있는 것입니다. <span class="orange">내 안에서 만들어 놓은 타인과 자신에 대한 부정적인 생각의 고리들을 끊어내야 합니다. 그래야 다시 시작할 수 있습니다. </span>용기를 내어 인생재건축을 시작해봅니다. 정확한 자기분석은 인생을 변화하는 첫걸음이 될 것입니다.'
         ],
         reconstruction: '나도 모르게 ‘나는 부적합한 사람이며 타인에게 거절당할 지도 모른다’는 두려움을 갖게 됩니다. 이와 동시에 ‘실패’에 대한 두려움에 내 능력을 제대로 발휘하지도 못하고, 창피당할 것이 두려워 감정을 숨기는 일이 많았습니다. 이 점은 스스로에 대한 과도한 기대 때문에 시작된 것일 수 있습니다. <span class="orange">더 잘해야 하고, 더 유능해 보여야 하고, 더 완벽해야 한다는 기대가 타인과 나를 비교하고 자꾸만 외부 시선을 의식하게 합니다.</span> <br/><br/>다른 사람의 칭찬을 얻기 위해 나 자신의 모습을 저버리는 일을 그만두어야 합니다. 그리고 용기를 내어 <span class="orange">‘내 모든 감정은 나의 것이며 나의 생각과 말과 행동은 타인의 인정과 감사를 받기 위해서가 아니라 내가 진정 원하기 때문’임을 선언해야 합니다.</span> <br/><br/>"나는 과거의 상처를 통해 배우고, 그것을 딛고 나 자신만의 길을 걸어갈 용기를 선택할 것입니다."'
      },
      70: {
         review: '당신은 지금 ‘변화’가 필요한, 인생의 중요한 변곡점에 서 있습니다. 변화는 당신에게 선택의 문제가 아니라 ‘용기’의 문제입니다. ',
         remember: '당신은 삶에 대한 만족도가 그리 높지 않습니다. 돈과 명예, 권력, 외모 등 밖으로 보여지는 ‘나’를 위해 많은 힘을 쓰고 살아왔습니다. 남들은 눈치채지 못했을지라도, 자신은 스스로에 대해 만족하지 못하고 실망도 많았을 것입니다. 자칫 존재에 대한 공허함으로 무언가에 중독될 수 있는 가능성이 있습니다. <br/><br/><span class="orange">내면에 채워지지 않은 나의 갈망을 ‘재소환’해서 기억해야 합니다. 내가 원했던 것, 바라고 지향하며 살았던 것, 나의 인생의 목표와 방향에 대한 총체적인 점검을 해야 할 시간입니다.</span> 내가 할 수 있는 것과 할 수 없는 것을 잘 구별하여, 할 수 없는 것들은 ‘나를 위해’ 내려놓고, 할 수 있는 것 가운데에서 무엇에 집중할지를 선택해야 합니다. <br/><br/>자신에 대한 불안이 타인에 대한 증오나 공격성을 표출하는 방향으로 나아가서는 안 됩니다. 상대와의 논쟁을 멈추고, 진짜 ‘나’를 찾아 나가는 작업을 시작해야 합니다. 다시 숨을 고르고 인생의 지향과 목표를 점검하는 시간이 필요합니다.',
         reinterpretation: '심리학자 융(C.G.Jung)이 집단 심리의 한 측면으로 설명하는 개념이 ‘페르소나(가면)’입니다. 페르소나는 사회가 그 사람에게 기대하는 역할로, 우리는 그 가면을 쓰고 배우처럼 연기합니다. 그것은 부정적인 것만이 아닌 게, 사회생활을 하는데 어쩔 수 없는 요소이기 때문입니다. 바깥세상은 멋진 페르소나, 가령 ‘친절한’, ‘유능한’, ‘명랑한’, ‘가정적인’ 가면을 가진 사람들을 특별히 우대하기 때문에 우리는 페르소나와 자신을 동일시하고 싶은 유혹에 빠지게 됩니다. 하지만 인생의 위기상황에 이르면 우리는 그 ‘가짜’에 한계를 느끼고 부담스럽게 생각합니다. 참된 자신의 모습, 진정한 ‘자기’를 만나고자 합니다. 인생의 어려운 순간 “나는 누구인가?”라는 핵심 질문을 비로소 던지게 됩니다. <br/><br/><span class="orange">당신을 해석하는 중요한 지점은 ‘내가 다른 사람들에게 보이고자 했던 모습’을 성찰하는 데서부터 시작합니다.</span> 타인의 좋은 평판을 얻기 위해 나는 어떤 노력을 했었는지 기억해 내는 것이 출발점이 되면 좋겠습니다. 진짜 ‘나’에서 벗어나 있는 가짜 ‘나’를 찾아내는 작업이 잘 된다면 당신은 성공적인 자기 변화를 이룰 수 있게 될 것입니다. ',
         refresh: '<strong>Self Imagination Training 자기 이미지 훈련이 필요합니다.<br/><br/></strong> 새로운 무엇인가를 창조하기 위해서는 <span class="orange">일상적으로 생각하고 느끼는 방식을 변화시켜야 합니다.</span> 매일 동일한 방식으로 생각하고 느낀다면 계속해서 동일한 상황을 만들어 낼 수밖에 없을 것입니다. 이미 ‘자신’이 되어버린 삶에서 벗어나기 위해서는 삶의 환경보다 더 크게 생각하고, 몸에 기억된 느낌보다 더 커져야 하며 새로운 시간선(영원)을 만들어야 합니다. <span class="orange">변화하고자 하는 이상적인 ‘나’를 이미지화해야 합니다.</span> <br/><br/>뇌가 과거의 완전한 기록이고 마음이 의식의 산물이라고 할 때, 어떤 의미에서 우리는 항상 과거 속에서 생각하고 있다고 할 수 있습니다. 기억에 상응하는 뇌의 어떤 부분이 반응함으로써 우리는 과거와 동일한 수준의 마음을 만들어 내고 있는 것입니다. 무의식적인 행동을 하면서 우리는 과거의 자아와 연결되어 있습니다. <span class="orange">만족스럽지 못한 나의 모습을 변화시키고자 하지만 나는 오늘도 이전과 다르지 않은 똑같은 생각, 똑같은 행동, 똑같은 감정을 매일 경험하고 있습니다.</span> 과연 이렇게 과거의 에너지에 묶여 우리의 미래 인생을 변화시킬 수 있을까요? 변화된 나의 이상적인 모습을 그려내야 합니다. 그것이 시작입니다.',
         reformation: [
            {
               name: '낙인찍기',
               content: '당신이나 타인에 대해 전반적으로 부정적으로 평가하거나 단정 짓는다.',
               result: '“나는 어차피 안 될 놈이다.”<br/>“그는 무책임한 사람이다.”',
               tip: '자신의 판단이 감정적 반응이나 고정관념에서 비롯된 것은 아닌지 성찰하고 열린 마음으로 받아들이는 태도를 유지해야 합니다.',
            },
            {
               name: '부정적 필터링',
               content: '부정적인 것에만 초점을 두어 바라본다.',
               result: '“어차피 그 사람은 나를 떠날거예요.”<br/>“그 일을 하지 말았어야 했어요.”',
               tip: '당신이 바라보는 것이 현실이 됩니다. 누구에게나 동일하게 펼쳐지는 세상임을 인식하며 당신의 초점이 어디, 어느 지점에 가 있는지 성찰해 보아야 합니다.',
            },
         ],
         reformation_desc: [
            '<span class="orange">인지 왜곡 점검을 통해 당신 안에 있는 부정적인 신념을 구분하고 분류해야 합니다.</span> ‘낙인찍기’의 부정적 패턴은 사실 본인 스스로에 대한 인식의 투사일 가능성이 높습니다. 부정적인 사고를 깨지 않고서는 새로운 것이 들어갈 자리를 마련할 수 없습니다.',
            '<span class="orange">‘낙인찍기’는 직관을 가장한 선입견일 수 있습니다. 세상에 대한 무분별한 부정적인 렌즈를 내려놓아야 합니다. </span>내가 가진, 부정적 인식의 회로를 인식하는 것에서부터 새로운 세상이 시작될 것입니다. 내가 할 수 있는 일과 없는 일을 잘 구별해야 합니다. 나의 부정적 인식에 실체가 있는지, 무엇이 현실이고 무엇이 추측이고 가정인지 분별할 수 있어야 합니다. 가치 있고, 의미 있는 일에 힘을 싣고 살아야 나의 남은 인생이 더욱 빛날 것입니다. 용기를 내십시오, 이제 진짜 내 삶을 시작해야 하는 시간이 왔습니다.'
         ],
         reconstruction: '우리는 스스로 포기하지 못하는 것들에 묶여 더 중요한 것을 놓치는 경우들이 많습니다. 우선, 나의 능력을 과장하려는 욕구를 포기해야 합니다. 나에 대한 과도한 기대를 내려놓는 것부터 시작해야 할 것이고, 이것은 나를 다른 사람과 비교하려고 하는 마음을 멈출 수 있도록 도와줄 것입니다. <span class="orange">나 자신이 아닌, 다른 사람의 칭찬을 얻기 위해 자신의 모습을 저버리는 일을 이제는 그만두어야 합니다.</span> <br/><br/>나의 내면에는 내가 인정하지 않아 발현되지 않은 강력한 힘이 있습니다. 두려움 없이 내 모습 그대로를 누구에게나 보여 줄 수 있는 힘이 있고, 누구보다 강하고 독립적인 존재임을 믿어야 합니다. 뿐만 아니라, 나는 타인을 잘 돌보고, 배려하며 선한 마음을 가지고 있음을 믿어야 합니다. 다른 사람의 성취와 성공을 내 일처럼 기뻐할 줄 알며, 타인과의 비교나 경쟁이 의미 없음을 알고 있습니다. <span class="orange">누구도 대신 채워줄 수 없는 ‘나에 대한 확신’을 나 스스로 바로 세워야 합니다.</span> <br/><br/>"나는 내 삶의 주인으로서, 나의 가치를 스스로 정의하고 타인의 시선이나 평가에 흔들리지 않을 힘을 가질 것입니다."'
      },
      80: {
         review: '당신의 인생은 지금 용기를 내야 하는 시점에 이르렀습니다. 변화는 당신에게 선택의 문제가 아니라 ‘용기’의 문제입니다. ',
         remember: '<span class="orange">당신은 지금 변화해야 하는 시간에 이르렀습니다. 당신은 어떤 것이든 실행할 수 있는 힘을 가지고 있고, 동기부여도 충만합니다.</span> 모험과 성취, 결단과 인내, 변화와 도전을 받아들일 힘이 있습니다. 그러나 아직 많이 배워야 하는 시간이고 경험해야 하는 시간입니다. <br/><br/><span class="orange">당신이 지금 생의 순간에 기억해야 할 것은 ‘중용’의 가치입니다. 주어진 삶에 만족하면서도 유연함과 융통성을 가져야 성공하고 성취하고 앞으로 나아갈 수 있습니다.</span> 주어진 것들에서 해방되어 자유를 체험하지만 스스로에게 온전히 만족하지 못하고 있습니다. 내 삶과 인생에 대해 내면으로부터 온전히 만족하지 못하면 삶에는 늘 ‘불안’이라는 구름이 끼어 듭니다. 그 불안은 때로는 나를 ‘우울’하게 하기도 하고 나를 ‘분노’하게 만들기도 합니다. 그래서 정확한 자기분석(Self Analysis)이 필요한 것입니다.',
         reinterpretation: '심리학자 융(C.G.Jung)이 집단 심리의 한 측면으로 설명하는 개념이 ‘페르소나(가면)’입니다. 페르소나는 사회가 그 사람에게 기대하는 역할로, 우리는 그 가면을 쓰고 배우처럼 연기합니다. 그것은 부정적인 것만이 아닌 게, 사회생활을 하는데 어쩔 수 없는 요소이기 때문입니다. 바깥세상은 멋진 페르소나, 가령 ‘친절한’, ‘유능한’, ‘명랑한’, ‘가정적인’ 가면을 가진 사람들을 특별히 우대하기 때문에 우리는 페르소나와 자신을 동일시하고 싶은 유혹에 빠지게 됩니다. 하지만 인생의 위기상황에 이르면 우리는 그 ‘가짜’에 한계를 느끼고 부담스럽게 생각합니다. 참된 자신의 모습, 진정한 ‘자기’를 만나고자 합니다. 인생의 어려운 순간 “나는 누구인가?”라는 핵심 질문을 비로소 던지게 됩니다. <br/><br/><span class="orange">당신을 해석하는 중요한 지점은 ‘내가 다른 사람들에게 보이고자 했던 모습’을 성찰하는 데서부터 시작합니다.</span> 타인의 좋은 평판을 얻기 위해 나는 어떤 노력을 했었는지 기억해 내는 것이 출발점이 되면 좋겠습니다. 진짜 ‘나’에서 벗어나 있는 가짜 ‘나’를 찾아내는 작업이 잘 된다면 당신은 성공적인 자기 변화를 이룰 수 있게 될 것입니다.',
         refresh: '<strong>Self Imagination Training 자기 이미지 훈련이 필요합니다.<br/><br/></strong> 새로운 무엇인가를 창조하기 위해서는 <span class="orange">일상적으로 생각하고 느끼는 방식을 변화시켜야 합니다.</span> 매일 동일한 방식으로 생각하고 느낀다면 계속해서 동일한 상황을 만들어 낼 수밖에 없을 것입니다. 이미 ‘자신’이 되어버린 삶에서 벗어나기 위해서는 삶의 환경보다 더 크게 생각하고, 몸에 기억된 느낌보다 더 커져야 하며 새로운 시간선(영원)을 만들어야 합니다. <span class="orange">변화하고자 하는 이상적인 ‘나’를 이미지화해야 합니다.</span> <br/><br/>뇌가 과거의 완전한 기록이고 마음이 의식의 산물이라고 할 때, 어떤 의미에서 우리는 항상 과거 속에서 생각하고 있다고 할 수 있습니다. 기억에 상응하는 뇌의 어떤 부분이 반응함으로써 우리는 과거와 동일한 수준의 마음을 만들어 내고 있는 것입니다. 무의식적인 행동을 하면서 우리는 과거의 자아와 연결되어 있습니다. <span class="orange">만족스럽지 못한 나의 모습을 변화시키고자 하지만 나는 오늘도 이전과 다르지 않은 똑같은 생각, 똑같은 행동, 똑같은 감정을 매일 경험하고 있습니다.</span> 과연 이렇게 과거의 에너지에 묶여 우리의 미래 인생을 변화시킬 수 있을까요? 변화된 나의 이상적인 모습을 그려내야 합니다. 그것이 시작입니다.',
         reformation: [
            {
               name: '이분법적 사고',
               content: '전부 아니면 전무, 빛과 어둠, 정의-불의로 나누어 생각한다. ',
               result: '“나는 모두에게 거부당한다.”<br/>“공무원들은 부정, 부패하다.”',
               tip: '세상에는 빛과 어둠만이 존재하지 않습니다. 선과 악, 천사와 악마, 시시비비만으로 세상을 이해할 수 없습니다. <span class="orange">이분법적인 사고는 흑백논리를 만들고 나의 선택과 판단의 영역을 좁게 만들어 버립니다. </span>세상은 그렇게 두 개로 쪼개져 존재하지 않습니다.',
            },
            {
               name: '당위적 사고',
               content: '모든 것들을 ‘~해야만 한다’라는 관점에서 해석한다.',
               result: '“이겨야만 한다.”<br/>“정직해야 한다.”<br/>“한 우물을 파야 한다.”',
               tip: '반드시 해야만 하는 것은 없습니다. 그저 하는 것입니다. <span class="orange">때로 우리는 이러한 당위에 묶여 스스로를 세상의 가장 좁은 감옥에 집어넣습니다. 그것은 바로 ‘나’라고 하는 감옥입니다.</span>',
            },
         ],
         reformation_desc: [
            '우리는 종종 세상을 흑백으로 나누거나, "반드시 그래야만 한다"는 생각에 갇히는 경우가 있습니다. 이분법적 사고와 당위적 사고는 상황을 단순화하거나 자기 자신과 타인에게 불필요한 부담을 줄 수 있습니다. 이를 극복하기 위해 다양한 관점을 받아들이고, 유연한 사고방식을 연습하는 것이 중요합니다.',
            '세상은 하나의 정답만 있는 단순한 곳이 아니라, 다양한 가능성과 선택지가 공존하는 풍부한 공간임을 기억해야 합니다. 자신과 타인에게 더 너그러운 시각을 가지며, 완벽함보다 성장과 균형을 추구하는 태도가 우리를 더 자유롭게 할 것입니다.',
         ],
         reconstruction: '우리는 스스로 포기하지 못하는 것들에 묶여 더 중요한 것을 놓치는 경우들이 많습니다. 우선, 나의 능력을 과장하려는 욕구를 포기해야 합니다. 나에 대한 과도한 기대를 내려놓는 것부터 시작해야 할 것이고, 이것은 나를 다른 사람과 비교하려고 하는 마음을 멈출 수 있도록 도와줄 것입니다. <span class="orange">나 자신이 아닌, 다른 사람의 칭찬을 얻기 위해 자신의 모습을 저버리는 일을 이제는 그만두어야 합니다. 내 안에는 나약하고 부족한 모습이 있지만, 스스로 전체성과 완전성을 실현할 수 있는 ‘성장하는 인간’, 특별한 사람입니다.</span> <br/><br/>나의 내면에는 내가 인정하지 않아 발현되지 않은 강력한 힘이 있습니다. 두려움 없이 내 모습 그대로를 누구에게나 보여 줄 수 있는 힘이 있고, 누구보다 강하고 독립적인 존재임을 믿어야 합니다. 뿐만 아니라, 나는 타인을 잘 돌보고, 배려하며 선한 마음을 가지고 있음을 믿어야 합니다. 다른 사람의 성취와 성공을 내 일처럼 기뻐할 줄 알며, 타인과의 비교나 경쟁이 의미 없음을 알고 있습니다. <span class="orange">나와 타인의 성장을 위해 나 자신을 확장시킬 의지가 내 안에 있습니다. 누구도 대신 채워줄 수 없는 ‘나에 대한 확신’을 나 스스로 바로 세워야 합니다.'
      },
      90: {
         review: '당신은 바른 견해를 가지고 있는 사람입니다. 열린 마음을 가지고 있습니다.당신 안에는 ‘나와 타인의 성장을 위해 나를 확장시키려는 의지’가 있습니다. 이제 이 의지를 구체적으로 실현해야 할 때입니다. ',
         remember: '<span class="orange">당신은 지금 통합해야 하는 시간에 이르렀습니다. 당신은 어떤 것이든 실행할 수 있는 힘을 가지고 있고, 동기부여도 충만합니다.</span> 모험과 성취, 결단과 인내, 변화와 도전을 받아들일 힘이 있습니다. 아직 많이 배워야 하는 시간이고 경험해야 하는 시간입니다. <br/><br/><span class="orange">당신이 지금 생의 순간에 기억해야 할 것은 ‘중용’의 가치입니다. 주어진 삶에 만족하면서도 유연함과 융통성을 가져야 성공하고 성취하고 앞으로 나아갈 수 있습니다.</span> 주어진 것들에서 해방되어 자유를 체험하지만 스스로에 대한 확신이 흔들릴 때가 있습니다. 내 삶과 인생에 대해 내면으로부터 온전히 만족하지 못하면 삶에는 늘 ‘불안’이라는 구름이 끼어 듭니다. 그 불안은 때로는 나를 ‘우울’하게 하기도 하고 나를 ‘분노’하게 만들기도 합니다. 그래서 정확한 자기분석(Self Analysis)이 필요한 것입니다.',
         reinterpretation: '<span class="orange">당신은 신뢰할만한 사람이며, 자유로운 영혼입니다. 항상 삶에 만족하고, 융통성이 있으며 유연함을 가지고 살아갑니다. 특히, 결과보다는 과정을 소중하게 생각하고, 타인을 긍정하고 자신을 인정합니다.</span> 그러한 자신감과 여유가 당신을 더 안전한 자리에 머물게 하고, 시시비비와 판단을 넘어선 자리에서 당신은 공동체에 선한 영향력을 줄 수 있는 사람입니다. <br/><br/>당신은 Meta-Q점수로 ‘중도’ 역량의 수준에 있을 가능성이 높습니다. 이 단계에서는 일을 적절히 하는데 그치지만, 그 다음 단계 자발적이고 창의적인 수준에서는 다가오는 일들을 보다 다른 차원으로 확장시키고 변화시킬 능력을 가집니다. 이 단계에서는 변화가 빠르게 일어나며 주제들에 대한 이해의 수준이 깊어집니다. 이들은 <span class="orange">자연스럽게 다른 이들에게 도움이 되고, ‘공동선’에 공헌합니다.</span> 또한, <span class="orange">내적 문제를 직면하며 배우는데 큰 장애물이 없습니다.</span> 당신에게는 이 단계로 나아갈 내면의 힘이 있음을 믿고 실현해야 합니다. ',
         refresh: '<strong>Retreat_피정 </strong><br/><br/>피정(避靜)은 피세정념(避世靜念)의 줄임말입니다. 세상을 피해 마음을 모아들인다는 말입니다. ‘Retreat’는 전쟁 중에 후퇴한다는 의미도 있습니다. 세상은 온통 전쟁터 같습니다. 날마다 벌어지는 많은 일들이 우리 마음을 혼란하게 합니다. <span class="orange">집을 떠나 작고 고요한 자리에 머물러 그동안 살아온 인생을 돌아보고 성찰하며 새로운 삶을 다짐하고 자신을 변화시킬 수 있는 시간이 필요합니다.</span> <br/><br/><strong>Walking Meditation_걷기명상</strong><br/><br/> 인생은 피정의 연속입니다. 걷기 명상은 걷기의 신체적 효과에, 정신적 건강을 위해 ‘명상의 마음챙김’을 합친 개념입니다. 걸으면서 자신을 돌아봅니다. 몸을 관찰하면서 주변 세상과 ‘혼연일체’가 되어야 합니다. 소리를 듣고, 냄새를 맡고, 사물을 바로 보아야 합니다. 발이 땅에 어떻게 닿는지도 유심히 관찰하고 생각해 봅니다. 이런 감각에 완전히 몰입하려고 애써야 합니다. 다른 생각을 과감히 떨쳐내야 합니다. <span class="orange">서서히 삶을 새롭게 자각할 수 있는 단순한 행동을 취하며 복잡한 마음을 정돈합니다.</span> 걷기 명상을 통해 우리는 자기 자신에게 더 친절해지고, 자신의 정체성과 행위에 대한 섣부른 판단을 줄이고 집중력을 높일 수 있었다는 고백을 듣게 됩니다.',
         reformation: [
            {
               name: '개인화',
               content: '부정적인 사건에 대해 자신을 탓하거나 비난한다. ',
               result: '“나 때문에 죽었다.”<br/>“나 때문에 일이 잘못됐다.” ',
               tip: '<span class="orange">이미 지나간 과거의 사건 가운데 아직도 내 마음을 묶어두어 자유를 방해하는 일들이 있습니다. </span>그 일은 나 때문에 일어난 것이 아니며, 내가 책임질 수도 없음을 알지만 마음으로부터 놓아주기가 힘듭니다.',
            },
            {
               name: '후회하는 태도',
               content: '과거에 ‘더 잘했어야 했는데’라고 생각한다.',
               result: '“그런 말을 해서는 안 되는 것이었는데.”<br/>“공부를 좀 더 열심히 했어야 했는데.”',
               tip: '지나간 일에 대한 후회나 원망도 이제는 모두 내려놓아야 합니다. 당신은 그때 나름의 최선을 다했던 것입니다. 당신이 다른 어떤 선택을 했었더라도 지금과 크게 달라지진 않았을 것입니다. <span class="orange">그저 지나간 일은 우리에게 늘 교훈이었음을 명심해야 합니다.</span>',
            },
         ],
         reformation_desc: [
            '부정적인 사건이 일어날 때, 우리는 종종 모든 책임을 자신에게 돌리거나 "그때 그렇게 했더라면" 하며 후회에 사로잡히곤 합니다. 이러한 개인화와 후회는 문제 해결보다는 스스로를 더욱 위축시키고 감정적으로 소진되게 만듭니다. 이를 멈추기 위해, 사건의 원인을 객관적으로 분석하고, 과거를 바라보는 대신 현재와 미래를 위한 행동에 집중하는 태도가 필요합니다.',
            '피정이나, 걷기명상 등의 방법으로 내 안에 묶여있는 해결되지 않는 감정을 분명히 하고 떠나보내는 연습을 해야 합니다. 일상 중에 연습할 수도 있고, 특별히 시간을 내어 차분히 정돈하는 시간을 갖는 것도 중요합니다.',
         ],
         reconstruction: '<span class="orange">‘창조적인 삶’을 산다는 것은 ‘아무도 아닌(nobody)’ 존재로 산다는 것입니다.</span> 우리는 자꾸 무엇인가, 누군가가 되려고 합니다. 어디인가 소속되려고 하고, 무언가에 기대어 자신의 존재를 증명하려고 합니다. 무엇인가 되려는 것이, 누구인가로 존재하려는 것이 나의 스트레스의 핵심입니다. 자신을 잊어버릴 정도로 어딘가에 몰입해 있었던 경우가 있습니까? 몰입의 순간에는 당신이 이미 알고 있는 세계에서 분리됩니다. 더이상 내가 소유한 물건들, 내가 아는 사람들, 해야 하는 일들, 특정한 장소에 살았던 이런저런 장소들과 관련된 누군가가 아닙니다. 무아(無我)의 상태가 되는 것입니다. <span class="orange">우리가 더 이상 외부환경 속에 사람과 장소, 사물에 초점을 맞추지 않는다면 우리는 인생을 새롭게 재건축할 수 있을 것입니다. 당신의 변화를 응원하고 지지합니다.</span> <br/><br/>"과거는 바꿀 수 없지만, 현재의 선택은 나의 미래를 바꿀 수 있음을 기억해야 합니다. 스스로를 탓하기보다 배운 것을 바탕으로 더 나은 길을 찾아가는 것이 진정한 성장이자 회복의 시작입니다."'
      },
      91: {
         review: '감성에서 이성으로, 이성에서 영성으로. 당신 안에는 ‘나와 타인의 성장을 위해 나를 확장시키려는 의지’가 가득하고 바위도 움직일만큼의 힘이 있습니다. ',
         remember: '이성 그 자체로는 진실에 대한 지침을 제공하지 못합니다. 이성은 논리의 방법론이 두드러지는 기술 세계에서 대단히 효과적이지만 역설적으로 더 높은 의식 수준에 도달하는 데에는 중대한 장애물입니다. 이성은 특정한 것을 다루지만 사랑은 전체를 다룹니다. 사랑은 위치를 취하지 않고, 따라서 전체적이며 위치성의 분리를 넘어섭니다. 그러면 ‘서로 함께하는 것’이 가능한데 어떤 장벽도 없기 때문입니다. 사랑은 포괄적이고 계속해서 자아 감각을 확장합니다. <br/><br/>사랑이 점점 더 무조건적이게 되면서, 내면의 기쁨으로 경험되기 시작합니다. 기쁨이 모든 활동에 끊임없이 동반됩니다. 기쁨은 어떤 외부적 근원에서가 아니라 존재의 매 순간 내면에서 발생합니다. 91점 이상은 <span class="orange">치유의 수준이며. 영적인 것에 기반하는 자조 그룹의 수준입니다.</span>',
         reinterpretation: '<span class="orange">주관과 객관 사이의 구별이 사라지며 지각의 특정한 중심점이 없습니다.</span> 이 수준에 있는 개인들은 흔히 스스로 세상에서 물러납니다. 어떤 이들은 영적 스승이 되고 어떤 이들은 인류의 향상을 위해 익명으로 일합니다. 몇몇은 각자의 분야에서 위대한 천재가 되어 사회에 크게 이바지합니다. 보통 공식 종교는 초월 되고 <span class="orange">모든 종교의 기원이 되는 순수 영성</span>으로 대체됩니다. <br/><br/>이 수준에서 보는 세상은 의의와 근원이 압도적인, 절묘하게 조율된 진화적 춤 속에서 계속해서 흐르고 진화하게 되었습니다. 이 놀라운 드러남은 <span class="orange">마음에 무한한 침묵이 자리하며 개념화를 중단</span>시켰습니다. <span class="orange">바위처럼 굳건한 힘의 현존 때문에 모든 것이 다른 모든 것과 연결됩니다.</span> 위대한 미술 작품과 음악, 건축물은 우리를 일시적으로 더 높은 의식 수준들로 데려갈 수 있으며 또한 영감을 주고 시대를 초월하는 것으로 널리 인정받습니다. ',
         refresh: '<strong>마인드 리허설(Mind Rehearsal) : 생각이 경험이 된다.<br/><br/></strong> 마음을 신경과학에서는 ‘활동 중인 뇌’라고 말합니다. 생각을 달리하는 것만으로도 뇌를 변화시킬 수 있습니다. 생각만으로 나의 행동과 태도, 믿음을 바꿀 수 있습니다. 머릿속에서 시연함으로써(Mind Rehearsal) 뇌의 회로를 변경하는 것입니다. 이제 생각을 바꾸면 뇌가 바뀝니다. 뇌를 변화시키기 위해서는 몇 가지 전제가 필요합니다. 첫째, <span class="orange">새로운 지식을 학습해야</span> 합니다. 둘째, <span class="orange">직접 코칭을 받는 것</span>이 중요합니다. 셋째, <span class="orange">주의를 기울이며 의식해야</span> 합니다. 마지막으로, <span class="green">반복하면서 뇌의 시냅스를 활성화시켜야</span> 합니다. 계속되는 학습은 시냅스의 연결을 만들어 냅니다. <br/><br/>오랫동안 홀로 변하고자 했는데 그러지 못한 사람에게 도움을 주고자 한다면 이와 같은 과정을 안내할 수 있습니다. 익숙한 수련자의 지도에 따라 마음을 훈련시켜 새로운 경험이 뇌의 시냅스 연결을 더욱 강화시킬 수 있다면 뇌의 연결 회로는 변하고, 나는 변화할 수 있습니다. 결국 ‘뇌’를 바꾸는 노력을 해야 ‘나’를 변화시킬 수 있는 것입니다. <span class="orange">‘뇌’가 변해야 ‘내’가 변합니다.</span>',
         reformation: [
            {
               name: '후회하는 태도',
               content: '과거에 ‘더 잘했어야 했는데’라고 생각한다.',
               result: '“그런 말을 해서는 안 되는 것이었는데.”<br/>“공부를 좀 더 열심히 했어야 했는데.”',
               tip: '이제 지나간 일들에 대해 후회나 원망을 할 필요가 없습니다. 당신은 충분히 지금 여기 잘살고 있기 때문입니다.',
            },
            {
               name: '스스로 비난하는 (자기비판 영역)',
               content: '‘바라는 나’, ‘이상적인 내 모습’을 그려놓고 이에 부합하지 않는 자신을 비난한다.',
               result: '“모두에게 존경받아야 하는데 사람들은 모두 나를 싫어한다.”',
               tip: '스스로 자신을 비난하며 왜곡된 인지를 하는 부분은 없는지 확인하고 구체적인 행동의 변화를 계획해 봅니다.',
            },
         ],
         reformation_desc: [
            '당신 안에 있는 강력한 의지와 잠재력을 더 키우기 위해, 스스로에게 끊임없이 질문하고 내면의 목소리를 경청해 보세요. 작은 일이라도 실천하며 자신의 성장 과정을 스스로 축하하고, 타인의 성장을 진심으로 응원하는 태도를 유지하세요. 그리고 어려움이 닥칠 때마다 이를 배움의 기회로 삼아, 당신만의 고유한 길을 계속 걸어가세요',
            '당신은 이미 성장과 변화를 위한 모든 가능성을 품고 있습니다. 아직 남아 있는 부적절한 행동 패턴이나 인지습관은 스스로 구체적인 계획을 세워 바로잡을 수 있습니다. 이제 그 가능성을 믿고, 한 걸음씩 나아가며 당신만의 빛을 세상에 드러내세요.'
         ],
         reconstruction: '우리는 ‘누군가’로 살아가려고 합니다. 사회적인 역할, 책임, 위치, 관계의 양상, 교류, 직업 등을 통해 누군가가 되어 존재합니다. 그런데 그런 모습은 진짜 내가 아닙니다. 그것은 생존을 위하여 선택한 나의 전략이고 모습입니다. 어떤 직업으로서 일하는 내가 진정한 ‘나’는 아닙니다. <br/><br/>누군가와의 관계로 만들어진, 어떤 조직과의 관계로 규정된 내가 ‘나’는 아닙니다. 우리는 무엇인가를 내어 맡긴다는 것을 어려워합니다. 다른 누구에게 나에 대한 통제권을 준다는 것인데 이러한 것을 허용하는 관념을 받아들이기 어려워하는 것입니다. 그러나 무한한 힘, 신성한 능력을 가진 무한한 지혜에 자신을 내맡긴다는 것은 조금 더 수월할 것입니다. <br/><br/>에고의 통제를 이제 내려놓아야 한다는 것입니다. <span class="orange">눈에 보이지 않는 힘은 실재하며, 나의 에고가 잠잠해질 때 최고의 해결책이 흘러나올 수 있습니다. 내려놓으면서 가져야 할 감정은 진실, 성실, 겸손, 정직, 확신, 명료함, 열정, 신뢰입니다.</span>'
      }
   }

   // pdf 저장
   useEffect(() => {
      const handleDownloadPdf = async () => {

         const element = printPageRefs[0].current
         if (!element) {
            return
         }
         // ========== 기본 세팅 ========== 
         const pdf = new jsPDF('p', 'mm', 'a4');

         // A4 사이즈 기준으로 캔버스를 PDF 크기에 맞추기
         const pdfWidth = 210;
         const pdfHeight = 297;
         // 캔버스 크기와 PDF 크기 비율 계산
         const ratio = Math.min(pdfWidth / element.offsetWidth, pdfHeight / element.offsetHeight);

         // 이미지의 너비와 높이를 비율에 맞게 조정
         const imgWidth = element.offsetWidth * ratio;
         const imgHeight = element.offsetHeight * ratio;


         //  ========== 페이지 추가 ========== 
         for (let i = 0;i < printPageRefs.length;i++) {
            try {
               const element = printPageRefs[i].current;
               if (!element) {
                  console.log('fail: ', element)
                  continue;
               }
               const canvas = await html2canvas(element, {
                  scale: 1.0,
                  useCORS: true,
               });
               const imgData = canvas.toDataURL('image/png');
               if (i > 0) pdf.addPage(); // 첫 페이지는 addPage 필요 없음
               pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');

               setPdfDownload(Math.ceil(((i + 1) / printPageRefs.length) * 100))
            } catch (error) {
               console.log(error)
            }
         }
         const timestamp = Date.now()
         // pdf.save(`insight_pdf_file_${timestamp}.pdf`);

         // ========== 서버로 전송 ========== 
         const pdfBlob = pdf.output('blob');

         // 서버로 보낼 FormData 생성
         const formData = new FormData();
         formData.append('birth', isPdfDownloadStart.birth);
         formData.append('pdf_file', pdfBlob, `insight_pdf_file_${timestamp}.pdf`); // 파일 이름 지정

         try {
            // 서버로 전송
            await axios.post(`${process.env.REACT_APP_API_URL}/inspect/life/save/pdf`, formData, {
               headers: {
                  Authorization: `Bearer ${accessToken}`
               },
            });

            setPdfDownloadStart((prev) => ({
               ...prev,
               start: false,
            }))

            navigate('/life-score/done')
         } catch (error) {
            console.error('파일 전송 실패:', error);
            ShowErrorMsg(error)
            window.location.href = '/'
         }
      }

      handleDownloadPdf();
   }, [])

   // return
   if (!images || !resultPageData || !ref29ScoreResult) return;

   return (
      <Container >
         <div className='page-common' ref={printPageRefs[0]}>
            <main className="main cover">
               <img src={images.life_score_cover} alt="" className="cover-img" />
            </main>
         </div>
         <div className='page-common' ref={printPageRefs[1]}>
            <main className="main">
               <p className="page-num">01</p>
               <div className="main-title-wrap col-group">
                  <div className="num dan orange">01</div>
                  <div className="main-title-group row-group">
                     <h2 className="main-title">
                        인생점수
                     </h2>
                     <p className="main-sub-title">
                        나는 내 인생에 왜 지금과 같은 점수를 주었는지 마인드 인사이트를 통해 <br />
                        체계적으로 점검, 분석해 보시기를 추천드립니다.
                     </p>
                  </div>
               </div>

               <div className="section-wrap row-group">
                  <div className="section">
                     <div className="age-wrap col-group gap16">
                        <div className="age-item row-group gap48">
                           <div className="col-group gap24 al-ce">
                              <img src={images.age_icon_0_on} alt="" className="icon" />
                              <div className="row-group gap8">
                                 <p className="default-txt default-txt-ss">
                                    10세 이전
                                 </p>
                                 <p className="default-txt default-txt-bold">
                                    영유아기
                                 </p>
                              </div>
                           </div>
                           <div className="row-group gap4">
                              <div className="age-score-wrap col-group">
                                 <div className={`age-score ${resultPageData[0] >= 10 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[0] >= 20 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[0] >= 30 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[0] >= 40 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[0] >= 50 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[0] >= 60 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[0] >= 70 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[0] >= 80 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[0] >= 90 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[0] >= 100 ? 'active' : ''}`}></div>
                              </div>
                              <p className="default-txt default-txt-ss right">
                                 {resultPageData[0]}점
                              </p>
                           </div>
                        </div>
                        <div className="age-item row-group gap48">
                           <div className="col-group gap24 al-ce">
                              <img src={resultPageData.age > 10 ? images.age_icon_1_on : images.age_icon_1} alt="" className="icon" />
                              <div className="row-group gap8">
                                 <p className="default-txt default-txt-ss">
                                    10~20세
                                 </p>
                                 <p className="default-txt default-txt-bold">
                                    10대
                                 </p>
                              </div>
                           </div>
                           <div className="row-group gap4">
                              <div className="age-score-wrap col-group">
                                 <div className={`age-score ${resultPageData[10] >= 10 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[10] >= 20 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[10] >= 30 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[10] >= 40 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[10] >= 50 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[10] >= 60 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[10] >= 70 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[10] >= 80 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[10] >= 90 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[10] >= 100 ? 'active' : ''}`}></div>
                              </div>
                              <p className="default-txt default-txt-ss right">
                                 {resultPageData[10]}점
                              </p>
                           </div>
                        </div>
                        <div className="age-item row-group gap48">
                           <div className="col-group gap24 al-ce">
                              <img src={resultPageData.age > 20 ? images.age_icon_2_on : images.age_icon_2} alt="" className="icon" />
                              <div className="row-group gap8">
                                 <p className="default-txt default-txt-ss">
                                    21~30세
                                 </p>
                                 <p className="default-txt default-txt-bold">
                                    20대
                                 </p>
                              </div>
                           </div>
                           <div className="row-group gap4">
                              <div className="age-score-wrap col-group">
                                 <div className={`age-score ${resultPageData[20] >= 10 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[20] >= 20 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[20] >= 30 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[20] >= 40 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[20] >= 50 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[20] >= 60 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[20] >= 70 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[20] >= 80 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[20] >= 90 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[20] >= 100 ? 'active' : ''}`}></div>
                              </div>
                              <p className="default-txt default-txt-ss right">
                                 {resultPageData[20]}점
                              </p>
                           </div>
                        </div>
                        <div className="age-item row-group gap48">
                           <div className="col-group gap24 al-ce">
                              <img src={resultPageData.age > 30 ? images.age_icon_3_on : images.age_icon_3} alt="" className="icon" />
                              <div className="row-group gap8">
                                 <p className="default-txt default-txt-ss">
                                    31~40세
                                 </p>
                                 <p className="default-txt default-txt-bold">
                                    30대
                                 </p>
                              </div>
                           </div>
                           <div className="row-group gap4">
                              <div className="age-score-wrap col-group">
                                 <div className={`age-score ${resultPageData[30] >= 10 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[30] >= 20 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[30] >= 30 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[30] >= 40 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[30] >= 50 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[30] >= 60 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[30] >= 70 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[30] >= 80 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[30] >= 90 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[30] >= 100 ? 'active' : ''}`}></div>
                              </div>
                              <p className="default-txt default-txt-ss right">
                                 {resultPageData[30]}점
                              </p>
                           </div>
                        </div>
                        <div className="age-item row-group gap48">
                           <div className="col-group gap24 al-ce">
                              <img src={resultPageData.age > 40 ? images.age_icon_4_on : images.age_icon_4} alt="" className="icon" />
                              <div className="row-group gap8">
                                 <p className="default-txt default-txt-ss">
                                    41~50세
                                 </p>
                                 <p className="default-txt default-txt-bold">
                                    40대
                                 </p>
                              </div>
                           </div>
                           <div className="row-group gap4">
                              <div className="age-score-wrap col-group">
                                 <div className={`age-score ${resultPageData[40] >= 10 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[40] >= 20 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[40] >= 30 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[40] >= 40 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[40] >= 50 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[40] >= 60 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[40] >= 70 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[40] >= 80 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[40] >= 90 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[40] >= 100 ? 'active' : ''}`}></div>
                              </div>
                              <p className="default-txt default-txt-ss right">
                                 {resultPageData[40]}점
                              </p>
                           </div>
                        </div>
                        <div className="age-item row-group gap48">
                           <div className="col-group gap24 al-ce">
                              <img src={resultPageData.age > 50 ? images.age_icon_5_on : images.age_icon_5} alt="" className="icon" />
                              <div className="row-group gap8">
                                 <p className="default-txt default-txt-ss">
                                    51~60세
                                 </p>
                                 <p className="default-txt default-txt-bold">
                                    50대
                                 </p>
                              </div>
                           </div>
                           <div className="row-group gap4">
                              <div className="age-score-wrap col-group">
                                 <div className={`age-score ${resultPageData[50] >= 10 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[50] >= 20 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[50] >= 30 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[50] >= 40 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[50] >= 50 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[50] >= 60 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[50] >= 70 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[50] >= 80 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[50] >= 90 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[50] >= 100 ? 'active' : ''}`}></div>
                              </div>
                              <p className="default-txt default-txt-ss right">
                                 {resultPageData[50]}점
                              </p>
                           </div>
                        </div>
                        <div className="age-item row-group gap48">
                           <div className="col-group gap24 al-ce">
                              <img src={resultPageData.age > 60 ? images.age_icon_6_on : images.age_icon_6} alt="" className="icon" />
                              <div className="row-group gap8">
                                 <p className="default-txt default-txt-ss">
                                    61~70세
                                 </p>
                                 <p className="default-txt default-txt-bold">
                                    60대
                                 </p>
                              </div>
                           </div>
                           <div className="row-group gap4">
                              <div className="age-score-wrap col-group">
                                 <div className={`age-score ${resultPageData[60] >= 10 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[60] >= 20 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[60] >= 30 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[60] >= 40 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[60] >= 50 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[60] >= 60 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[60] >= 70 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[60] >= 80 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[60] >= 90 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[60] >= 100 ? 'active' : ''}`}></div>
                              </div>
                              <p className="default-txt default-txt-ss right">
                                 {resultPageData[60]}점
                              </p>
                           </div>
                        </div>
                        <div className="age-item row-group gap48">
                           <div className="col-group gap24 al-ce">
                              <img src={resultPageData.age > 70 ? images.age_icon_7_on : images.age_icon_7} alt="" className="icon" />
                              <div className="row-group gap8">
                                 <p className="default-txt default-txt-ss">
                                    71~80세
                                 </p>
                                 <p className="default-txt default-txt-bold">
                                    70대
                                 </p>
                              </div>
                           </div>
                           <div className="row-group gap4">
                              <div className="age-score-wrap col-group">
                                 <div className={`age-score ${resultPageData[70] >= 10 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[70] >= 20 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[70] >= 30 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[70] >= 40 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[70] >= 50 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[70] >= 60 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[70] >= 70 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[70] >= 80 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[70] >= 90 ? 'active' : ''}`}></div>
                                 <div className={`age-score ${resultPageData[70] >= 100 ? 'active' : ''}`}></div>
                              </div>
                              <p className="default-txt default-txt-ss right">
                                 {resultPageData[70]}점
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className={`section life-score-section ${resultPageData.age < 10 && 'age0' ||
                     resultPageData.age < 20 && 'age1' ||
                     resultPageData.age < 30 && 'age2' ||
                     resultPageData.age < 40 && 'age3' ||
                     resultPageData.age < 50 && 'age4' ||
                     resultPageData.age < 60 && 'age5' ||
                     resultPageData.age < 70 && 'age6' ||
                     resultPageData.age < 80 && 'age6'
                     }`}>
                     <div className="life-score-title-wrap col-group gap16">
                        <img src={images.icon_life_orange} alt="" className="img" />
                        <p style={{ marginBottom: '0px' }} className="life-score-title">
                           <span className="orange">인생점수</span> 결과
                        </p>
                     </div>
                     <div className="life-score-result-wrap col-group gap56">
                        <div className="row-group gap56">
                           <div className="title-wrap col-group gap40">
                              <div className="icon"></div>
                              <div className="row-group gap24">
                                 <div className="txt-group col-group gap32">
                                    <p className="default-txt default-txt-bold">
                                       {resultPageData.name}
                                    </p>
                                    <p className="default-txt">
                                       {resultPageData.age}세
                                    </p>
                                 </div>
                                 <div className="score">
                                    평균 {resultPageData.life_average}점
                                 </div>
                              </div>
                           </div>
                           <div className="row-group gap32">
                              <p className="default-txt">
                                 본 인생점수는, 지나온 각자의 시간들에 대해 스스로 평가한 <br />
                                 만족도 지표입니다.
                              </p>
                              <p className="default-txt default-txt-l default-txt-bold orange">
                                 {
                                    ref29ScoreResult &&
                                    ref29ScoreResult[ref29ScoreType] &&
                                    ref29ScoreResult[ref29ScoreType].review || 'review가 없습니다'
                                 }
                              </p>
                           </div>
                        </div>
                        <div className="chart">
                           <div className="chart-txt">
                              {resultPageData.life_average}점
                           </div>
                           <Doughnut data={chartData2} options={chartOptions2} />
                        </div>
                     </div>
                  </div>
               </div>
            </main>
         </div>
         <div className='page-common' ref={printPageRefs[2]}>
            <main className="main">
               <p className="page-num">02</p>

               <div className="section-wrap row-group">
                  <div className="section">
                     <div className="section-title-wrap col-group">
                        <div className="num">1</div>
                        <h3 className="section-title">
                           <span className="orange">R</span>emember 기억하기
                        </h3>
                     </div>
                     <div className="row-group gap24">
                        {/* <p className="default-txt default-txt-bold">
                           시대는 빠르게 변화하고 있습니다. 내가 틀릴 수도 있다라는 생각으로 변화를 시작해야합니다.
                        </p> */}
                        <p className="default-txt" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ref29ScoreResult[ref29ScoreType].remember) }} />
                     </div>
                  </div>
                  <div className="section">
                     <div className="section-title-wrap col-group">
                        <div className="num">2</div>
                        <h3 className="section-title">
                           <span className="orange">R</span>einterpretation 재해석하기
                        </h3>
                     </div>
                     <div className="row-group gap24">
                        {/* <p className="default-txt default-txt-bold">
                           몸을 새로운 마음에 길들여 조화를 이루게 하는 무의식 재학습 프로그램
                        </p> */}
                        <p className="default-txt" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ref29ScoreResult[ref29ScoreType].reinterpretation) }} />
                     </div>
                  </div>
                  <div className="section">
                     <div className="section-title-wrap col-group">
                        <div className="num">3</div>
                        <h3 className="section-title">
                           <span className="orange">R</span>efresh 새롭게 하기
                        </h3>
                     </div>
                     <div className="row-group gap24">
                        {/* <p className="default-txt default-txt-bold orange">
                           '지금여기'에 좋은 사람과 좋은 시간을 보내는 것이 방법입니다.
                        </p> */}
                        <p className="default-txt" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ref29ScoreResult[ref29ScoreType].refresh) }} />
                     </div>
                  </div>
               </div>
            </main>
         </div>
         <div className='page-common' ref={printPageRefs[3]}>
            <main className="main">
               <p className="page-num">03</p>

               <div className="section-wrap row-group">
                  <div className="section">
                     <div className="section-title-wrap col-group">
                        <div className="num">4</div>
                        <h3 className="section-title">
                           <span className="orange">R</span>eformation 개선하기
                        </h3>
                     </div>
                     <div className="row-group gap24">
                        <p className="default-txt" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ref29ScoreResult[ref29ScoreType].reformation_desc[0]) }} />
                        <div className="col-group gap24">
                           {ref29ScoreResult[ref29ScoreType].reformation.map((item, index) => (
                              <div className="green-border-box row-group gap24 center" key={index}>
                                 <p className="default-txt default-txt-bold orange">
                                    {item.name}
                                 </p>
                                 <p className="default-txt default-txt-s">
                                    {item.content}
                                 </p>
                                 <img src={images.icon_arrow_bold_down_orange} alt="" className="icon-arrow-down" />
                                 <p
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.result) }}
                                    className="default-txt default-txt-s default-txt-bold"
                                 />
                              </div>
                           ))}
                        </div>
                        <div className="col-group gap24">
                           {ref29ScoreResult[ref29ScoreType].reformation.map((item, index) => (
                              item.tip &&
                              <div className="green-tip-box row-group gap16 center" key={index}>
                                 <div className="icon">TIP</div>
                                 <p
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.tip) }}
                                    className="default-txt default-txt-bold"
                                 />
                              </div>
                           ))}
                        </div>
                        <p className="default-txt" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ref29ScoreResult[ref29ScoreType].reformation_desc[1]) }} />
                     </div>
                  </div>
                  <div className="section">
                     <div className="section-title-wrap col-group">
                        <div className="num">5</div>
                        <h3 className="section-title">
                           <span className="orange">R</span>econstruction 새로 세우기
                        </h3>
                     </div>
                     <div className="col-group gap56">
                        <img src={images.Reconstruction} alt="" className="recons-img" />
                        <p className="default-txt" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ref29ScoreResult[ref29ScoreType].reconstruction) }} />
                     </div>
                  </div>
               </div>
            </main>
         </div>
         <div className='page-common align-top' ref={printPageRefs[4]}>
            <main className='main'>
               <div className="section">
                  <div className="section-title-wrap col-group">
                     <div className="num">6</div>
                     <h3 className="section-title">
                        <span className="orange">"너 자신을 R하라"</span>
                     </h3>
                  </div>
                  <p className="default-txt">
                     본 인생점수는, 지나온 각자의 시간들에 대해 스스로 평가한 만족도 지표입니다. 어느 질문에는 쉽게 점수를 주었고, 어떤 부분에서는 멈칫했으며, 어느 지점에서는 생각이 많아졌을 것입니다. <strong className="orange">모든 기억에는 이유와 의미가 있습니다.</strong> 덮어두고 지나갔던 그 지점으로 돌아가 재해석하거나 개선하거나 재건축하지 않으면, 그 지점에서 시작되어 굳어진 마음에 변화를 줄 수 없습니다. <br />
                     <br />
                     모든 사람은 사회적입니다. 당신은 혼자 만들어지지 않았고, 당신이 생각하는 그 문제의 원인은 당신에게만 있는 것이 아닙니다. <strong className="orange">나를 키운 사람들, 내 주변에 있었던 친구들, 나와 교류했던 동료들, 내가 사랑하고 미워했던 모든 사람들이 지금의 나를 만들었습니다.</strong> 나를 만든 수많은 요소와 그 영향, 그에 따라 형성된 나의 성향과 신념체계, 교류패턴들을 함께 분석하지 않으면 제대로 된 나를 알 수 없습니다. <br />
                     <br />
                     <strong className="orange">본 인생점수는 내 안의 ‘진짜 나’를 알아가고 발견하는 총체적인 과정을 담은 종합검사지 ‘마인드인사이트’의 일부분입니다. ‘마인드인사이트’는 나의 정서상태를 시작으로 몸과 마음의 상관관계, 숨겨진 역량, 내가 사용하는 에너지의 방향과 힘의 균형, 타인과의 교류패턴 분석 등을 담은 종합보고서를 만듭니다.</strong> <br />
                     <br />
                     나는 내 인생에 왜 지금과 같은 점수를 주었는지 마인드인사이트를 통해 체계적으로 점검, 분석해 보시기를 추천드립니다.
                  </p>
               </div>
            </main>
            <img className='r-bg-img' src={images.r_bg} alt='너 자신을 R하라 배경 이미지' />
         </div>
      </Container >
   )
}

export default LifeScorePageContainer