import React from 'react'
import { useRecoilValue } from 'recoil'
import { LoginInfoAtom } from '../../../recoil/LoginInfoAtom'
import Loading from '../../../components/common/Loading';
import FetchLogout from '../../../hooks/token/FetchLogout';
import { Link, useNavigate } from 'react-router-dom';
import useIsMobile from '../../../hooks/useIsMobile';

const MyPageMainPage = () => {

   const navigate = useNavigate();
   const isMobile = useIsMobile();
   const userInfo = useRecoilValue(LoginInfoAtom);

   if (userInfo === null) return <Loading />

   const isCounselor = userInfo.member_type === 1

   if (!isMobile) {
      navigate(`${isCounselor ? '/my-page/counselor/code-send' : '/my-page/general-user/code-use-gift'}`);
   }

   // 모바일용 마이페이지 메인
   return (
      <div className="subpage">
         <section className="myPage-section mypage_index">
            <div className="stateBox">
               <div className="container">
                  <div className="le-wrap col-group">
                     <i className="icon"></i>
                     <p className="title">마이페이지</p>
                     <p className="sub">
                        안녕하세요,&nbsp;
                        <span className="txt-bold">
                           {userInfo.name}
                           {isCounselor && ' 상담사'}
                           님
                        </span>
                     </p>
                  </div>
               </div>
            </div>
            <div className="container inner-container">
               <div className="myPageMenu">
                  {isCounselor ?
                     <div className="mp-inner-wrap">
                        <p className="mp-title">
                           마인드 인사이트
                        </p>
                        <div className="mp-list"> {/* 상담사 회원 메뉴 */}
                           <Link className="mp-item" to="/my-page/counselor/code-send">
                              검사코드 발송
                              <i className="xi-angle-right"></i>
                           </Link>
                           <Link className="mp-item" to="/my-page/counselor/send-history">
                              발송 내역
                              <i className="xi-angle-right"></i>
                           </Link>
                           <Link className="mp-item" to="/my-page/counselor/test-result">
                              심리검사 진행/결과
                              <i className="xi-angle-right"></i>
                           </Link>
                        </div>
                     </div>
                     : <div className="mp-inner-wrap">
                        <p className="mp-title">
                           마인드 인사이트
                        </p>
                        <div className="mp-list"> {/* 일반 회원 메뉴 */}
                           <Link className="mp-item" to="/my-page/general-user/code-use-gift">
                              검사코드 사용/선물
                              <i className="xi-angle-right"></i>
                           </Link>
                           <Link className="mp-item" to="/my-page/general-user/use-gift-history">
                              사용/선물 내역
                              <i className="xi-angle-right"></i>
                           </Link>
                           <Link className="mp-item" to="/my-page/general-user/progress">
                              심리검사 진행/결과
                              <i className="xi-angle-right"></i>
                           </Link>
                        </div>
                     </div>
                  }
                  <div className="mp-inner-wrap">
                     <p className="mp-title">
                        구매내역
                     </p>
                     <div className="mp-list">
                        <Link className="mp-item" to="/my-page/purchase/history">
                           구매내역
                           <i className="xi-angle-right"></i>
                        </Link>
                        <Link className="mp-item" to="/my-page/refund/history">
                           환불내역
                           <i className="xi-angle-right"></i>
                        </Link>
                     </div>
                  </div>
                  <div className="mp-inner-wrap">
                     <p className="mp-title">
                        1:1 문의
                     </p>
                     <div className="mp-list">
                        <Link className="mp-item" to="/my-page/inquiry">
                           1:1 문의
                           <i className="xi-angle-right"></i>
                        </Link>
                     </div>
                  </div>
                  <div className="mp-inner-wrap">
                     <p className="mp-title">
                        나의 정보
                     </p>
                     <div className="mp-list">
                        <Link className="mp-item" to="/my-page/account-management">
                           계정 정보 관리
                           <i className="xi-angle-right"></i>
                        </Link>
                     </div>
                  </div>

                  <button
                     onClick={FetchLogout}
                     className="default-btn logOut-btn col-group"
                  >
                     <p className="btn-title">로그아웃</p>
                     <i className="xi-log-out"></i>
                  </button>
               </div>
            </div>
         </section>
      </div>
   )
}

export default MyPageMainPage