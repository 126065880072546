import React from 'react'

// Components
import Id from '../../../../components/user/find-account/Id'

const FindIdCounselor = () => {
   return (
      <Id isCounselor={true} />
   )
}

export default FindIdCounselor