import React, { useEffect, useState } from 'react'

import TestProgressInfo from '../../../../components/user/my-page/TestProgressInfo'

const TestResult = () => {

   const [searchInfo, setSearchInfo] = useState('')
   const [searchValue, setSearchValue] = useState('');

   const searchOnChange = (e) => {
      setSearchValue(e.target.value)
   }
   const searchSubmit = (e) => {
      e.preventDefault();
      setSearchInfo(searchValue)
   }

   return (
      <div className="myPageContent counselor">
         <div className="mp-main-title-wrap border col-group">
            <h2 className="mp-main-title">
               <span className="mb">My 정보 <i></i></span>
               심리검사 진행/결과
            </h2>
            <div className="search-wrap">
               <form onSubmit={searchSubmit}>
                  <input
                     value={searchValue}
                     onChange={searchOnChange}
                     type="text"
                     className="search-input"
                     placeholder="이름을 입력하세요"
                  />
                  <button type='submit' className="search-btn">
                     <i></i>
                  </button>
               </form>
            </div>
         </div>

         <div className="test-result-list row-group">
            <TestProgressInfo searchInfo={searchInfo} />
         </div >
      </div >
   )
}

export default TestResult