import axios from 'axios';
import { useEffect, useState } from 'react';
import { ShowErrorMsg } from '../utils/ShowErrorMsg';

const useProductFetchData = (productId) => {
   const [fetchData, setFetchData] = useState();
   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/product/detail`, { id: productId })
            setFetchData(response.data.data)
         } catch (error) {
            console.error(error)
            ShowErrorMsg(error)
            window.location.href = '/'
            return null;
         }
      }
      fetchData();
   }, [])
   return fetchData;
}

export default useProductFetchData;