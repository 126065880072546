import React from 'react'
import { Outlet } from 'react-router-dom'
import MobileBar from './components/common/MobileBar';

// Hooks
import useIsMobile from './hooks/useIsMobile';
import useConfirmToken from './hooks/token/useConfirmToken';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import TopMenu from './components/TopMenu';

// Style
import 'swiper/css';
import 'swiper/css/pagination';
import './css/common.css'
import './css/style.css'

const Root = () => {

  // 토큰 확인 API
  useConfirmToken();

  const isMobile = useIsMobile()

  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
      <TopMenu />
      {isMobile && <MobileBar />}
    </div>
  )
}

export default Root